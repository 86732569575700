import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Paper } from "@material-ui/core";
// import { Widget, addResponseMessage } from 'react-chat-widget';
import "react-chat-widget/lib/styles.css";
import { Table } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { useHistory } from "react-router-dom";
import "react-chat-widget/lib/styles.css";
import Spinner from "react-bootstrap/Spinner";
import InputLabel from "@material-ui/core/InputLabel";
import { set } from "lodash";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// import "./showorder.css";
import { useToasts } from "react-toast-notifications";
import { toast } from "react-toastify";
import axios from "axios";
import { MixedWidget4 } from "../../../../_metronic/_partials/widgets";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Rating } from "@material-ui/lab";
import Countdown from "react-countdown";
import CountdownTimer from "../../../../Components/Timer/CountdownTimer";
import Axios from "axios";

// import Axios from "axios";

const OrderPage = (props) => {
  const history = useHistory();
  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }
  // const { addToast } = useToasts();

  const authtoken = localStorage.getItem("userToken");
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showoffer, setShowOffer] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageDialog, setMessageDialog] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const handleOfferClose = () => setShowOffer(false);
  const handleOfferShow = () => setShowOffer(true);
  const handleOfferClose = () => setShowOffer(false);
  const handleMessageClose = () => setShowMessage(false);
  // const [file, setFile] = useState([]);
  const [downloadfile, setDownloadFile] = useState({});
  const [filetype, setfileType] = useState("");
  const [filetypes, setFileTypes] = useState([]);
  const [revisionfiles, setRevisionFiles] = useState([]);
  const [orderfiles, setOrderfiles] = useState([]);
  const [attachorderdeliveryfiles, setOrderDeliveryFile] = useState([]);
  const [description, setOrderDescription] = useState("");
  const [payment, setOrderPayment] = useState("");
  const [userinfo, setUserInfo] = useState({});
  const [paymentvoucher, setPaymentVoucher] = useState([]);

  const [extentionTime, setExtentionTime] = useState("");

  var count = 1;
  useEffect(() => {
    getAllOrders();
    getAllFilesTypes();
    // OrderAttchmetFile();
    // addResponseMessage('Hi');
  }, []);

  const onchangeDescription = (val) => {
    setOrderDescription(val);
  };

  const onchangePayment = (value) => {
    setOrderPayment(value * 100);
  };

  const submitPayment = () => {
    setLoading(true);
    const data = {
      order_id: order.id,
      order_total_charges: payment,
    };
    // setLoading(true)
    axios
      .post(`${baseUrl}/order_charges_offer`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        console.log("Offer Payment", res.data);
        // setOrderPayment(res.data)
        // setShowOffer(false)
        setShowOffer(false);
        setLoading(false);
        window.location.reload(true);
        // toast("Payment Offer  Successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const DownloadFile = (id) => {
    console.log("this is id", id);
    // console.log('this is type id',typeid)
    axios
      .get(`${baseUrl}/order/requirement-file/download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        console.log("download file", res.data);
        setDownloadFile(res.data);
        var element = document.createElement("a");
        var file = new Blob([res.data], {
          type:
            res.data.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? ".docx"
              : res.data.type,
        });
        element.href = URL.createObjectURL(file);
        element.download =
          res.data.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? "order_file.docx"
            : res.data.type;
        element.click();
        // console.log("url is", url);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const OnSubmit = () => {
    setLoading(true);
    const data = new FormData();
    data.append("order_id", order.id);
    data.append("requirement_file[0][file]", attachorderdeliveryfiles);
    data.append("description[0]", description);

    // console.log('form data', data)

    axios
      .post(`${baseUrl}/admin/order_deliver`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        console.log("response from server", res.data);
        setShow(false);
        setLoading(false);
        deliverOrder();
       
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAllOrders = () => {
    setLoading(true);
    fetch(`${baseUrl}/show-order/${props.match.params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("order details", data.payload);
        setPaymentVoucher(data.payload.payment_voucher);
        setUserInfo(data.payload.user);
        setOrder(data.payload);
        setOrderfiles(data?.payload?.order_attachment_files);
        setRevisionFiles(data?.payload?.order_attachment_files);
        console.log("files", data?.payload?.order_attachment_files);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const ShowOrder = (id) => {
    history.push(`/order/show/${id}`);
    console.log(id);
  };
  // Order Approved

  const approvedOrder = (id) => {
    let data = {
      status: 2,
    };
    setLoading(true);
    console.log(id);
    fetch(`${baseUrl}/order/status-update/${order.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        getAllOrders();
        console.log("success");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //Deliver

  const deliverOrder = (id) => {
    let data = {
      status: 7,
    };
    fetch(`${baseUrl}/order/status-update/${order.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("success");
        getAllOrders();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Reject Order

  const rejectOrder = (id) => {
    let data = {
      status: 8,
    };
    fetch(`${baseUrl}/order/status-update/${order.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("success");
        getAllOrders();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllFilesTypes = () => {
    fetch(`${baseUrl}/order/file-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("types=>", data);
        setFileTypes([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Chat = (id) => {
    history.push(`/order/show/${id}`);
  };

  const Completionist = () => <span>Time is Over!</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  }

  if (order?.total_hours_of_duration) {
    var DAYS_IN_MS = order?.total_hours_of_duration * 60 * 60 * 1000;
    var NOW_IN_MS = new Date().getTime();

    var dateTimeAfter = NOW_IN_MS + DAYS_IN_MS;
  }

  const submitExtention = () => {
    const fdata = new FormData();
    fdata.append("order_id", order?.id);
    fdata.append("additional_hours", extentionTime);

    Axios.post(`${baseUrl}/extend_order_duration_admin`, fdata, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false)
      });
  };

  
 

  return (
    <>
      <div className="card-body d-flex flex-column p-0">
        <div className="row">
        <div className="col-lg-8 col-xxl-8 ">
          <div className="row m-0 bg-dark rounded-top">
              <div className="col px-8 py-6 mr-8 d-flex justify-content-between">
                <div className="font-size-h5 font-weight-bolder text-light ">
                  Order Details:
                </div>
                <div className="font-size-h5 font-weight-bolder text-light ">
                {order?.total_hours_of_duration && (
                    <CountdownTimer targetValue={dateTimeAfter} />
                  )}
                </div>
              </div>
            </div>
                <div className="card-spacer bg-white card-rounded flex-grow-1">
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                position: "absolute",
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <>
                   <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                <div className="font-size-sm text-muted font-weight-bold">Subject:</div>
                      <div className="font-size-h6 font-weight-bolder">
                        {order.subject?.name}
                      </div>
                    </div>
                <div className="col px-8 py-6 mr-8">
                <div className="font-size-sm text-muted font-weight-bold">Order Id:</div>
                      <div className="font-size-h6 font-weight-bolder">
                        {order.code_no}
                      </div>
                    </div>
                </div>

                  <div className="row m-0">
                    <div className="col px-8 py-6 mr-8">
                    <div className="font-size-sm text-muted font-weight-bold">Topic:</div>
                      <div className="font-size-h4 font-weight-bolder">
                        {order.topic}
                      </div>
                    </div>
                   
                  </div>

                  

                  <div className="row m-0">
                    <div className="col px-8 py-6 mr-8">
                      <div className="font-size-sm text-muted font-weight-bold">
                        Type of work:
                      </div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.type_of_work?.name}
                      </div>
                    </div>
                    <div className="col px-8 py-6">
                      <div className="font-size-sm text-muted font-weight-bold">
                      Type of Paper:
                      </div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.type_of_paper?.name}
                      </div>
                    </div>
                  </div>
                  {/* end::row */}
                  {/* begin::row */}
                  <div className="row m-0">
                    <div className="col px-8 py-6 mr-8">
                      <div className="font-size-sm text-muted font-weight-bold">
                      Paper Format
                      </div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.paper_format }
                      </div>
                    </div>
                    <div className="col px-8 py-6">
                      <div className="font-size-sm text-muted font-weight-bold">
                      Academic Level
                      </div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.academic_level?.name}
                      </div>
                    </div>
                  </div>
                  {/* end::row */}

                  <div className="row m-0">
                    <div className="col px-8 py-6 mr-8">
                    <div className="font-size-sm text-muted font-weight-bold">No. of Words:</div>
                    <div className="font-size-p font-weight-bolder mt-3">
                        {order.number_of_words}
                      </div>
                    </div>
                    
                      
                    <div className="col px-8 py-6">
                      <div className="font-size-sm text-muted font-weight-bold">
                      No. of Pages
                      </div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.number_of_pages}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col px-8 py-6 mr-8">
                    <div className="font-size-sm text-muted font-weight-bold">No. of Slides:</div>
                    <div className="font-size-p font-weight-bolder mt-3">
                        {order.number_of_slides}
                      </div>
                    </div>
                    
                      
                    <div className="col px-8 py-6">
                      <div className="font-size-sm text-muted font-weight-bold">
                        Sources Required
                      </div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.sources_required}
                      </div>
                    </div>
                  </div>


                  {/* begin::row */}
                  <div className="row m-0">
                
                    <div className="col px-8 py-6 mr-8">
                      <div className="font-size-sm text-muted font-weight-bold">
                        Specific Sources Required
                      </div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.specific_sources_required}
                      </div>
                    </div>
                    <div className="col px-8 py-6">
                    <div className="font-size-sm text-muted font-weight-bold">
                    Soft copies of Sources
                    </div>
                    <div className="font-size-p font-weight-bolder mt-3">
                    {order.soft_copies_of_sources}
                    </div>
                  </div>
                  </div>
                  {/* end::row */}

                   {/* begin::row */}
                   <div className="row m-0">
                  
               
                  <div className="col px-8 py-6 mr-8">
                    <div className="font-size-sm text-muted font-weight-bold">
                    Graph/Chart
                    </div>
                    <div className="font-size-p font-weight-bolder mt-3">
                    {order.charts_and_diagram}
                    </div>
                  </div>
                  <div className="col px-8 py-6">
                    <div className="font-size-sm text-muted font-weight-bold">
                    Table of Contents Required
                    </div>
                    <div className="font-size-p font-weight-bolder mt-3">
                    {order.table_of_contant}
                    </div>
                  </div>
                </div>
                {/* end::row */}

                 {/* begin::row */}
                 <div className="row m-0">
                  
                
                  <div className="col px-8 py-6 mr-8">
                    <div className="font-size-sm text-muted font-weight-bold">
                    Abstract Page Required
                    </div>
                    <div className="font-size-p font-weight-bolder mt-3">
                    {order.abstract_page}
                    </div>
                  </div>
                  <div className="col px-8 py-6">
                    <div className="font-size-sm text-muted font-weight-bold">
                    Appendices Required
                    </div>
                    <div className="font-size-p font-weight-bolder mt-3">
                    {order.appendices}
                    </div>
                  </div>
                </div>
                {/* end::row */}


                 {/* begin::row */}
                 <div className="row m-0">
                  
                 
                  <div className="col px-8 py-6 mr-8">
                    <div className="font-size-sm text-muted font-weight-bold">
                    Grammarly Report Required
                    </div>
                    <div className="font-size-p font-weight-bolder mt-3">
                    {order.grammarly_report_required}
                    </div>
                  </div>

                  <div className="col px-8 py-6">
                    <div className="font-size-sm text-muted font-weight-bold">
                    Turnitin Report Required
                    </div>
                    <div className="font-size-p font-weight-bolder mt-3">
                    {order.turnitin_report_required}
                    </div>
                  </div>
                </div>
                {/* end::row */}

                <div className="row m-0">
                    <div className="col px-8 py-6 mr-8">
                    <div className="font-size-sm text-muted font-weight-bold">Description:</div>
                      <div className="font-size-p font-weight-bolder mt-3">
                        {order.description}
                      </div>
                    </div>
                  </div>

                  {/* begin::Row */}
                  <div className="row m-0">
                    <div className="col px-8 py-6 mr-8">
                      <div className="font-size-sm text-muted font-weight-bold">
                        Attachment File:
                      </div>
                      <div className="font-size-h5 font-weight-bolder">
                        {orderfiles ? (
                          <div className="row col-md-12 col-sm-12">
                            {orderfiles.map((item,index) =>
                              item.file_type_id != 4 &&
                              item.file_type_id != 5 ? (
                                <a
                                key={index}
                                  className="ml-1"
                                  onClick={() => DownloadFile(item.id)}
                                >
                                  {/* <span style={{visibility:'hidden'}}>{item.requirement_file}</span> */}
                                  {/* <CloudDownloadIcon
                                    fontSize="large"
                                    className="download-pickfrompc"
                                  ></CloudDownloadIcon> */}
                                  {/* <span className="svg-icon svg-icon-xxl svg-icon-primary">
                            <SVG
                           
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Files/DownloadedFile.svg"
                              )}
                            ></SVG>
                          </span> */}
                                  <img
                                    src={toAbsoluteUrl(
                                      "/media/users/download.png"
                                    )}
                                    width={"45px"}
                                    height={"45px"}
                                  />
                                </a>
                              ) : null
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* end::Row */}

                  {order?.order_status?.id == 1 ? (
                    <>
                      <div className="row m-0">
                        <div className="col px-8 py-6 mr-8 d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={approvedOrder}
                          >
                            Approve
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger ml-4"
                            onClick={rejectOrder}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    </>
                  ) : order?.order_status?.id == 2 ? (
                    <>
                      <div className="row m-0">
                        <div className="col px-8 py-6 mr-8 d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-success ml-4"
                            onClick={() => {
                              // Chat(order.id);
                              history.push(`/chat/${order.id}`);
                            }}
                          >
                            Chat
                          </button>
                          {paymentvoucher[0] == null ? (
                            <button
                              type="button"
                              className="btn btn-success ml-4"
                              onClick={handleOfferShow}
                            >
                              Make Offer
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger ml-4"
                              onClick={handleShow}
                            >
                              Deliver
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : order?.order_status?.id == 8 ? null : order?.order_status
                      ?.id == 4 ? null : order?.order_status?.id ==
                    9 ? null : order?.order_status?.id == 7 ? null : null}
                
              {/* end::Order Details */}
              
              {/* </div> */}
            </>
          )}
          </div>
              </div>
          <div className="col-lg-6 col-xl-4 mt-5">
                <div className="row">
                  <div className="col ">
                    <div
                      className="bg-danger d-flex justify-content-center rounded-top py-8"
                      
                    >
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                          <div
                            className="symbol-label"
                            style={{
                              backgroundImage: `url(${user.profile_image})`,
                            }}
                          ></div>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="font-weight-bolder font-size-h5 text-light text-hover-dark"
                          >
                            {userinfo.name}
                            {/* {user.name} {user.lastname} */}
                          </a>
                          <div className="text-white">{userinfo.email}</div>
                        </div>
                      </div>
                      {/* end::User */}
                    </div>
                    {/* begin::Stats */}
                    <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                      {/* begin::Row */}
                      <div className="row m-0">
                        <div className="col px-8 py-6 mr-8">
                          <div className="font-size-sm text-muted font-weight-bold">
                            Client Id
                          </div>
                          <div className="font-size-h5 font-weight-bolder">
                            {userinfo.id}
                          </div>
                        </div>
                        <div className="col px-8 py-6">
                          <div className="font-size-sm text-muted font-weight-bold">
                            Role
                          </div>
                          <div className="font-size-h5 font-weight-bolder">
                            {userinfo.role}
                          </div>
                        </div>
                      </div>
                      {/* end::Row */}
                      {/* begin::Row */}
                      {/* <div className='row m-0'>
              <div className='col px-8 py-6 mr-8'>
                <div className='font-size-sm text-muted font-weight-bold'>Annual Taxes</div>
                <div className='font-size-h5 font-weight-bolder'>$29,004</div>
              </div>
              <div className='col px-8 py-6'>
                <div className='font-size-sm text-muted font-weight-bold'>Annual Income</div>
                <div className='font-size-h5 font-weight-bolder'>$1,480,00</div>
              </div>
            </div> */}
                      {/* end::Row */}
                    </div>
                    {/* end::Stats */}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col ">
                    <div
                      className="bg-dark d-flex justify-content-center rounded-top"
                      style={{ height: "100px", minHeight: "100px" }}
                    >
                      <div className="font-size-h5 text-white font-weight-bold d-flex align-items-center">
                        Payment Details
                      </div>
                    </div>
                    {/* begin::Stats */}
                    {paymentvoucher?.length > 0 ? (
                      paymentvoucher.map((item,index) =>
                        item.voucher_id ? (
                          <div className="card-spacer bg-white rounded-bottom flex-grow-1" key={index}>
                            <div className="row m-0">
                              <div className="col px-8 py-6 mr-8">
                                <div className="font-size-sm text-muted font-weight-bold">
                                  Voucher Date:
                                </div>
                                <div className="font-size-h6 font-weight-bolder">
                                  {new Date(item.created_at)
                                    .toLocaleDateString()
                                    .slice(0, 9)}
                                </div>
                              </div>
                              <div className="col px-8 py-6 mr-8">
                                <div className="font-size-sm text-muted font-weight-bold">
                                  Voucher ID:
                                </div>
                                <div className="font-size-h6 font-weight-bolder">
                                  {item.voucher_id}
                                </div>
                              </div>
                            </div>
                            {/* begin::Row */}
                            <div className="row m-0">
                              <div className="col px-8 py-6 mr-8">
                                <div className="font-size-sm text-muted font-weight-bold">
                                  Payment Price:
                                </div>
                                <div className="font-size-h6 font-weight-bolder">
                                  ${item.charges / 100}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      )
                    ) : (
                      <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                        <div className="row m-0">
                          <div className="col px-8 py-6 mr-8">
                            <div className="font-size-h5 font-weight-bolder">
                              There is no payment bid yet
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* end::Stats */}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col ">
                    <div
                      className="bg-danger d-flex justify-content-center rounded-top"
                      style={{ height: "100px", minHeight: "100px" }}
                    >
                      <div className="font-size-h5 text-white font-weight-bold d-flex align-items-center">
                        Review
                      </div>
                    </div>
                    {order?.reviews?.length > 0 ? (
                      order?.reviews?.map((item,index) => (
                        <>
                          {/* begin::Stats */}
                          <div className="card-spacer bg-white rounded-bottom flex-grow-1" key={index}>
                            {/* begin::Row */}
                            <div className="row m-0">
                              <div>
                                <div className="col px-8 py-6 mr-8">
                                  <div className="font-size-sm text-muted font-weight-bold">
                                    Rate:
                                  </div>
                                  <div className="font-size-h5 font-weight-bolder">
                                    {/* {item.customer_service_rating}/5 */}
                                    <Rating value={item.customer_service_rating} draggable={false} readOnly={true}/>
                                  </div>
                                </div>

                                <div className="col px-8 py-6 mr-8">
                                  <div className="font-size-sm text-muted font-weight-bold">
                                    Comment
                                  </div>
                                  <div className="font-size-h5 font-weight-bolder">
                                    {item.body}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                        <div className="row m-0">
                          <div className="col px-8 py-6 mr-8">
                            <div className="font-size-h5 font-weight-bolder">
                              There is no review yet
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
              <div className="col ">
                <div className="row mt-5">
                  <div className="col ">
                    <div
                      className="bg-dark d-flex justify-content-center rounded-top"
                      style={{ height: "100px", minHeight: "100px" }}
                    >
                      <div className="font-size-h5 text-white font-weight-bold d-flex align-items-center">
                        Duration Extend
                      </div>
                    </div>
                    <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                      {/* begin::Row */}
                      <div className="row m-0">
                        <div>
                          <div className="col px-8 py-6 mr-8">
                            <div className="font-size-sm text-muted font-weight-bold">
                              Entention in hours
                            </div>
                            <div className="font-size-h5 font-weight-bolder">
                              <input
                             
                                className="form-control"
                                type={"number"}
                                value={extentionTime}
                                onChange={(e) =>
                                  setExtentionTime(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col px-8 py-6 mr-8">
                            <div className="font-size-h5 font-weight-bolder">
                              <button
                               disabled={extentionTime.length>0?false:true}
                                className="btn btn-dark"
                                onClick={submitExtention}
                              >
                                Extend
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           

            
              </div>
        </div>
        <div className="row">
          <div className="table-responsive">
            <div className="row m-0">
              <div className="col px-8 py-6 mr-8">
                <div className="font-size-h5 font-weight-bolder">
                  Order Delivered
                </div>
              </div>
            </div>
            <table
              className="table table-vertical-center bg-white card-rounded"
              id="kt_advance_table_widget_1"
            >
              <thead className="thead-dark ">
                <tr className="text-left">
                  <th>#</th>
                  <th>Message</th>
                  <th className="text-center">Attachment File</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>

              <tbody>
                {order?.order_status?.id === 7 ? (
                  <>
                    {revisionfiles.map((deliverOrderfile,index) =>
                      deliverOrderfile.file_type_id == 4 ||
                      deliverOrderfile.file_type_id == 5 ? (
                        <>
                          <tr key={index}>
                            <td>{"Order Delivered-" + count++}</td>
                            <td>
                              {deliverOrderfile.description
                                .toString()
                                .slice(0, 32) + " "}
                              <a
                                className="text-primary"
                                onClick={() => {
                                  setMessageDialog(
                                    deliverOrderfile.description
                                  );
                                  setShowMessage(true);
                                }}
                              >
                                Show more...
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                className="row icon_style d-flex justify-content-center"
                                onClick={() =>
                                  DownloadFile(deliverOrderfile.id)
                                }
                              >
                                <CloudDownloadIcon fontSize="large">
                                  {downloadfile}
                                </CloudDownloadIcon>
                              </a>
                            </td>
                            <td className="text-center">
                              {new Date(deliverOrderfile.created_at)
                                .toLocaleString()
                                .slice(0, 9)}
                            </td>
                            <td className="text-center">
                              {deliverOrderfile.name}
                            </td>
                          </tr>
                        </>
                      ) : null
                    )}
                    <tr>
                      <td colSpan={5}>
                        <div className="row mt-10 order-action d-flex justify-content-center">
                          <div style={{ display: "inline-flex", gap: "12px" }}>
                            <button
                              type="button"
                              className="btn btn-danger ml-4"
                              onClick={handleShow}
                            >
                              Deliver again
                            </button>
                            {paymentvoucher[0] == null ? (
                              <button
                                type="button"
                                className="btn btn-success ml-4"
                                onClick={handleOfferShow}
                              >
                                Make Offer
                              </button>
                            ) : null}

                            <button
                              type="button"
                              className="btn btn-success ml-4"
                              onClick={() => {
                                // Chat(order.id);
                                history.push(`/chat/${order.id}`);
                              }}
                            >
                              Chat
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                ) : order?.order_status?.id === 4 ? (
                  <>
                    {revisionfiles.map((deliverOrderfile,index) =>
                      deliverOrderfile.file_type_id == 4 ||
                      deliverOrderfile.file_type_id == 5 ? (
                        <>
                          <tr key={index}>
                            <td>{"Order Delivered-" + count++}</td>
                            <td>
                              {" "}
                              {deliverOrderfile.description
                                .toString()
                                .slice(0, 32) + " "}
                              <a
                                className="text-primary"
                                onClick={() => {
                                  setMessageDialog(
                                    deliverOrderfile.description
                                  );
                                  setShowMessage(true);
                                }}
                              >
                                Show more...
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                className="row icon_style d-flex justify-content-center"
                                onClick={() =>
                                  DownloadFile(deliverOrderfile.id)
                                }
                              >
                                <CloudDownloadIcon fontSize="large">
                                  {downloadfile}
                                </CloudDownloadIcon>
                              </a>
                            </td>
                            <td className="text-center">
                              {new Date(deliverOrderfile.created_at)
                                .toLocaleString()
                                .slice(0, 9)}
                            </td>
                            <td className="text-center">
                              {deliverOrderfile.name}
                            </td>
                          </tr>
                        </>
                      ) : null
                    )}
                    <tr>
                      <td colSpan={5}>
                        <div className="row mt-10 order-action d-flex justify-content-center">
                          <div style={{ display: "inline-flex", gap: "12px" }}>
                            <button
                              type="button"
                              className="btn btn-danger ml-4"
                              onClick={handleShow}
                            >
                              Deliver again
                            </button>
                            {paymentvoucher[0] == null ? (
                              <button
                                type="button"
                                className="btn btn-success ml-4"
                                onClick={handleOfferShow}
                              >
                                Make Offer
                              </button>
                            ) : null}

                            <button
                              type="button"
                              className="btn btn-success ml-4"
                              onClick={() => {
                                // Chat(order.id);
                                history.push(`/chat/${order.id}`);
                              }}
                            >
                              Chat
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                ) : order?.order_status?.id === 9 ? (
                  <>
                    {revisionfiles.map((deliverOrderfile,index) =>
                      deliverOrderfile.file_type_id == 4 ||
                      deliverOrderfile.file_type_id == 5 ? (
                        <>
                          <tr key={index}>
                            <td>{"Order Delivered-" + count++}</td>
                            <td>
                              {" "}
                              {deliverOrderfile.description
                                .toString()
                                .slice(0, 32) + " "}
                              <a
                                className="text-primary"
                                onClick={() => {
                                  setMessageDialog(
                                    deliverOrderfile.description
                                  );
                                  setShowMessage(true);
                                }}
                              >
                                Show more...
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                className="row icon_style d-flex justify-content-center"
                                onClick={() =>
                                  DownloadFile(deliverOrderfile.id)
                                }
                              >
                                <CloudDownloadIcon fontSize="large">
                                  {downloadfile}
                                </CloudDownloadIcon>
                              </a>
                            </td>
                            <td className="text-center">
                              {new Date(deliverOrderfile.created_at)
                                .toLocaleString()
                                .slice(0, 9)}
                            </td>
                            <td className="text-center">
                              {deliverOrderfile.name}
                            </td>
                          </tr>
                        </>
                      ) : null
                    )}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>

        {/* Order Delivered Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Deliver Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={(e) => onchangeDescription(e.target.value)}
                  rows={3}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <div className="row">
                  <div className="col-md-6">
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{ fontSize: "1.1rem", fontWeight: "500" }}
                    >
                      Attachment File
                    </InputLabel>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      name="file"
                      type={"file"}
                      onChange={(e) => setOrderDeliveryFile(e.target.files[0])}
                    />
                  </div>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {loading ? (
              <Spinner
                variant="primary"
                animation="border"
                role="status"
              ></Spinner>
            ) : (
              <Button variant="primary" onClick={OnSubmit}>
                Submit
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* Payment Modal */}
        <Modal show={showoffer} onHide={handleOfferClose}>
          <Modal.Header closeButton>
            <Modal.Title>Payment Offer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row offer col-md-12">
              <div className="col-md-12 offer-id">
                <div>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: "1.1rem", fontWeight: "500" }}
                  >
                    Order ID
                  </InputLabel>
                </div>
                <div>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: "1.1rem" }}
                  >
                    {order.code_no}
                  </InputLabel>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                Payment
              </Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="inlineFormInputGroup"
                  placeholder="Payment"
                  onChange={(e) => onchangePayment(e.target.value)}
                />
              </InputGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleOfferClose}>
              Close
            </Button>
            {loading ? (
              <Spinner
                variant="primary"
                animation="border"
                role="status"
              ></Spinner>
            ) : (
              <Button variant="primary" onClick={submitPayment}>
                Send Offer
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={showMessage} onHide={handleMessageClose}>
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{messageDialog}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleMessageClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default OrderPage;
