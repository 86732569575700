import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-5.scss";
import Axios from "axios";
import { baseUrl } from "../../../services/config";

import { useToasts } from "react-toast-notifications";
import { Colors } from "../../../constants/Colors";


export function ErrorPage5() {
  const [loader,setLoader] =useState(false);
  const authtoken = localStorage.getItem("userToken");
  const { addToast } = useToasts();
  const resendEmail=()=>{
    console.log(authtoken);
    setLoader(true);

    fetch(`${baseUrl}/email/verification-notification`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
    .then(res=>res.json())
    .then((res)=>{
      addToast(res.metadata.messages, {
        appearance: "success",
        autoDismiss: true,
      });
      setLoader(false);
      // toast.success(res.metadata.messages)
      // console.log(res.metadata)
    })
  }
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`,
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
          <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">
            Oops!
          </h1>
          <p className="font-weight-boldest display-4">
            You are not verified yet.
          </p>
          <p className="font-size-h3 font-weight-light text-dark">
            Check your email
            <br />
            Click on the Verify Button.
            <br />
            After that you enjoy the services.
            <br/>
            <button className="btn rounded text-white mt-4" style={{background:`${Colors.primary}`}} disabled={loader} onClick={()=>resendEmail()} >Click here to resend the email</button>
          </p>

          
          {loader && <span className='ml-3 spinner spinner-black py-4'></span>}
        </div>
      </div>
    </div>
  );
}
