import React, { forwardRef } from 'react'
import './Message.css'
import { Card, CardContent, Typography } from '@material-ui/core'

const Message = forwardRef(({ message, username, isUser }, ref) => {
    // console.log('ujdtjtrdt kr',message)
    // console.log(isUser)
    return (
        <div ref={ref} className={`message ${isUser && 'message__user'}`} >
            <Card className={isUser ? "message__userCard" : 'message__guestCard'}>
                <CardContent>
                    <Typography
                       className='message_style'
                        variant="h6"
                        component="h6"
                    >
                        {/* {!isUser && `${message.username || 'Admin'}: `} */}
                        {message}
                    </Typography> 
                    {/* <h3 className='message_style'>{message}</h3> */}

                </CardContent>
            </Card>

        </div>


    )
}
)
export default Message
