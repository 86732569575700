import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { baseUrl } from "../../../../services/config";
import Axios from "axios";
import { Colors } from "../../../../constants/Colors";

const initialValues = {
  email: "",
  password: "",
  confirmpassword: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  let { id } = useParams();
  const history = useHistory();
  const [isRequested, setIsRequested] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .matches(  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirmpassword: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // const data={
      //   password:values.password,
      //   password_confirmation:values.confirmpassword
      // }

      const fdata = new FormData();

      fdata.append("password", values.password);
      fdata.append("password_confirmation", values.confirmpassword);

      Axios.post(
        `${baseUrl}/reset-password?token=${id}&email=${values.email}`,
        fdata
      )
        .then((response) => {
          history.push("/auth/login");
        })
        .catch((e) => console.log(e));

      // fetch(`${baseUrl}/reset-password?token=${id}&email=${values.email}`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(values),
      // })
      //   .then((response) => response.json())
      //   .then((values) => {
      //     console.log('Success:', values);

      //     // history.push('/auth/login');
      //   })
      //   .catch(() => {
      //     setStatus('Error');
      //   })
      //   .finally(() => {
      //     setSubmitting(false);
      //   });

      console.log(values);

      setSubmitting(true);
    },
  });

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1"  style={{color:`${Colors.primary}`}}>Reset Password?</h3>
            <div className="text-muted font-weight-bold">
              Enter your new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container password_div">
            <input
            placeholder="Password"
            type={passwordShown ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          <i
            className="bi bi-eye-slash password-icon"
            id="togglePassword"
            onClick={togglePassword}
          ></i>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                placeholder="Confirm Password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirmpassword"
                )}`}
                name="confirmpassword"
                {...formik.getFieldProps("confirmpassword")}
              />

              {formik.touched.confirmpassword &&
              formik.errors.confirmpassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirmpassword}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn text-white font-weight-bold px-9 my-3 mx-4"
                style={{backgroundColor:`${Colors.primary}`}}
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
