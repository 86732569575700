import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../services/config";
import "./style.css";
import { useToasts } from "react-toast-notifications";
import { Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ErrorPage5 } from "../../modules/ErrorsExamples/ErrorPage5";
export default function HireExpert() {
  const ref = useRef();
  const initalValues = {
    topic: "",
    subject: "",
    spacing: "",
    type_of_work: "",
    grammerly_report: "",
    academic_level: "",
    table_of_content: "",
    charts_and_diagram: "",
    amount_of_work: "",
    sources_required: "",
    soft_copies_of_sources: "",
    specific_sources_required: "",
    abstract_page: "",
    appendices: "",
    type_of_paper: "",
    turnitin_report: "",
    paper_format: "",
    description: "",
    number_of_pages: "",
    number_of_words: "",
    number_of_slides: "",
    dead_line_date: "",
  };

  const history = useHistory();

  const authtoken = localStorage.getItem("userToken");

  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }
  //states other

  const [subjects, setSubjects] = useState([]);
  const [work, setWork] = useState([]);
  const [amount, setAmount] = useState([]);
  const [sources, setSources] = useState([]);
  const [specific, setSpecific] = useState([]);
  const [softCopy, setSoftCopy] = useState([]);
  const [abstract, setAbstract] = useState([]);
  const [appendices, setAppendices] = useState([]);
  const [paper, setPaper] = useState([]);
  const [msg, setmsg] = useState(null);
  const [academic, setAcademic] = useState([]);
  const [filetypes, setFileTypes] = useState([]);

  // File Upload
  const [file, setFile] = useState([]);
  const [filetype, setfileType] = useState([]);
  const [currentSubject, setCurrentSubject] = useState("");

  const [topic, setTopic] = useState("");
  const [spacing, setSpacing] = useState("");
  const [wokrtype, setWorkType] = useState("");
  const [paperType, setPaperType] = useState("");
  const [academicLevel, setAcademicLevel] = useState("");
  const [tableOfContent, setTableOfContent] = useState("");
  const [chartsAndDiagram, setChartsAndDiagram] = useState("");
  const [amountWork, setAmountWork] = useState("");
  const [sourceWork, setSourceWork] = useState("");
  const [specificSource, setSpecificSource] = useState("");
  const [softCopySource, setSoftCopySource] = useState("");
  const [abstractSource, setAbstractSource] = useState("");
  const [appendiceSource, setAppendiceSource] = useState("");
  const [grammerlyReport, setGrammerlyReport] = useState("");
  const [turnitinReport, setTurnitinReport] = useState("");
  const [paperFormat, setPaperFormat] = useState("");
  const [deadline, setDeadline] = useState();
  const [submissionDeadlineType, setSubmissionDeadlineType] = useState();
  const [no_of_pages, setNoOfPages] = useState("");
  const [no_of_words, setNoOfwords] = useState("");
  const [no_of_slides, setNoOfSlides] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalMessage2, setModalMessage2] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const modalClose = () => setModalShow(false);
  const modalShowHandler = (
    title,
    message,
    modalMessage2,
    modalDescription
  ) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalMessage2(modalMessage2);
    setModalDescription(modalDescription);
    setModalShow(true);
  };

  const [abstractError, setAbstractError] = useState(false);
  const [appendicesError, setAppendicesError] = useState(false);
  const [grammerlyError, setGrammerlyError] = useState(false);
  const [turnitinError, setTurnitinError] = useState(false);

  const { addToast } = useToasts();

  const [loading, setloading] = useState(false);

  const [inputList, setInputList] = useState([{ requirement_file: [] }]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    list[index][name] = e.target.files;
    console.log(list);

    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { requirement_file: "" }]);
    console.log("input Values====> " + JSON.stringify(inputList));
  };

  // const { addToast } = useToasts();

  useEffect(() => {
    getAllSubjects();
    getAllWork();
    getAllPaper();
    getAllAcademic();
    getAllFilesTypes();
  }, []);

  const getAllFilesTypes = () => {
    fetch(`${baseUrl}/order/file-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("types=>", data);
        setFileTypes([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ValidationSchemaForm = Yup.object().shape({
    topic: Yup.string().required("Topic is required"),
    subject: Yup.string().required("Subject is required"),
    spacing: Yup.string().required("Spacing is required"),

    type_of_work: Yup.string().required("Please select type_of_work"),
    type_of_paper: Yup.string().required("Please select type_of_paper"),
    academic_level: Yup.string().required("Please select academic_level"),
    table_of_content: Yup.string().required("Please select table_of_content"),
    charts_and_diagram: Yup.string().required("Charts and Diagram Required"),
    sources_required: Yup.string().required("Please select sources_required"),
    soft_copies_of_sources: Yup.string().required(
      "Please select soft_copies_of_sources"
    ),
    specific_sources_required: Yup.string().required(
      "Please select specific_sources_required"
    ),
    paper_format: Yup.string().required("please select Paper Format"),
    description: Yup.string().required("Please enter some description"),
    
  });

  //dynamic styling for inputs

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  // Api Functions

  const getAllSubjects = () => {
    fetch(`${baseUrl}/order/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSubjects([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllWork = () => {
    fetch(`${baseUrl}/order/type-of-work`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setWork([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPaper = () => {
    fetch(`${baseUrl}/order/type-of-paper`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPaper([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAcademic = () => {
    fetch(`${baseUrl}/order/academic-level`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAcademic([...data.payload]);
        toast("Order placed Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: initalValues,
    validationSchema: ValidationSchemaForm,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values);
      if (grammerlyReport == "") {
        setGrammerlyError(true);
        return;
      } else if (turnitinReport == "") {
        turnitinError(true);
        return;
      } else if (abstractSource == "") {
        abstractError(true);
        return;
      } else if (appendiceSource == "") {
        appendicesError(true);
        return;
      } else {
        setloading(true);
        const fdata = new FormData();

        fdata.append("subject", values.subject);
        fdata.append("number_of_pages", values.number_of_pages);
        fdata.append("number_of_words", values.number_of_words);
        fdata.append("number_of_slides", values.number_of_slides);

        fdata.append("type_of_work", values.type_of_work);
        fdata.append("type_of_paper", values.type_of_paper);
        fdata.append("sources_required", values.sources_required);
        fdata.append("topic", values.topic);
        fdata.append("spacing", values.spacing);
        fdata.append("table_of_contant", values.table_of_content);
        fdata.append("charts_and_diagram", values.charts_and_diagram);
        fdata.append("paper_format", values.paper_format);

        fdata.append("grammarly_report_required", grammerlyReport);
        fdata.append("turnitin_report_required", turnitinReport);
        fdata.append(
          "specific_sources_required",
          values.specific_sources_required
        );
        fdata.append("soft_copies_of_sources", values.soft_copies_of_sources);
        fdata.append("academic_level", values.academic_level);
        fdata.append("abstract_page", abstractSource);
        fdata.append("appendices", appendiceSource);
        fdata.append("description", values.description);
        fdata.append("total_hours_of_duration", submissionDeadlineType=='Urgent'? +deadline:+deadline*24);

        for (let index = 0; index < inputList.length; index++) {
          const element = inputList[index];
          console.log(element);
          fdata.append(
            `requirement_file[${index}][file]`,
            element.requirement_file[0]
          );
        }

        for (var pair of fdata.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        axios
          .post(`${baseUrl}/store-order`, fdata, {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
          .then((res) => {
            if (res.data.metadata.success) {
              addToast(res.data.metadata.messages[0], {
                appearance: "success",
                autoDismiss: true,
              });
              history.push("/history/allorders");
              setloading(false);
            } else {
              setloading(false);
              addToast(res.data.metadata.messages[0], {
                appearance: "error",
                autoDismiss: true,
              });
            }
          })
          .catch((error) => {
            setloading(false);
            console.log(error)
          });
      }
    },
  });

  const abstracSourceHandler = (e) => {
    if (e == "") {
      setAbstractError(true);
    } else if (e == "yes") {
      setAbstractError(false);
      setAbstractSource(e);
      modalShowHandler(
        "Abstract Page Required",
        "Dear Client, please remember that you need to pay extra for Abstract page."
      );
    } else {
      setAbstractError(false);
      setAbstractSource(e);
    }
  };

  const appendicesSourceHandler = (e) => {
    if (e == "") {
      setAppendicesError(true);
    } else if (e == "yes") {
      setAppendicesError(false);
      setAppendiceSource(e);
      modalShowHandler(
        "Appendices Required",
        "Dear Client, please remember that you need to pay extra for Appendices."
      );
    } else {
      setAppendicesError(false);
      setAppendiceSource(e);
    }
  };

  const grammerlySourceHandler = (e) => {
    if (e == "") {
      setGrammerlyError(true);
    } else if (e == "yes") {
      setGrammerlyError(false);
      setGrammerlyReport(e);
      modalShowHandler(
        "Grammarly Report Required",
        "Dear Client, we don't charge for Grammarly Reports, enjoy it for free!"
      );
    } else {
      setGrammerlyError(false);
      setGrammerlyReport(e);
    }
  };

  const turnitinSourceHandler = (e) => {
    if (e == "") {
      setTurnitinError(true);
    } else if (e == "yes") {
      setTurnitinError(false);
      setTurnitinReport(e);
      modalShowHandler(
        "Turnitin Required",
        "Dear Client, please remember that you need to pay $5 extra for Turnintin Report."
      );
    } else {
      setTurnitinError(false);
      setTurnitinReport(e);
    }
  };

  const noOfWords = (e) => {
    modalShowHandler(
      "No. of Words",
      "Please mention the exact number of words you want in this assignment."
    );
  };
  const noOfPages = (e) => {
    modalShowHandler(
      "No. of Pages",
      "1 Double-Spaced Page = 300 Words",
      "1 Single Spaced Page = 600 Words",
      ""
    );
  };
  const noOfSlides = (e) => {
    modalShowHandler(
      "No. of Slides",
      "Please mention the number of PowerPoint Slides you want in this order."
    );
  };
  const spacingDialog = (e) => {
    modalShowHandler(
      "Spacing",
      "Single Spacing means 1.0 Line Spacing",
      "Double Spacing means 2.0 Line Spacing",
      ""
    );
  };
  const chartsDialog = (e) => {
    modalShowHandler(
      "Charts and Diagrams",
      "",
      "",
      "Please mention the charts, figures, pictures, or graphs you need."
    );
  };
  const descriptionDialog = (e) => {
    modalShowHandler(
      "Description",
      "Dear Client,",
      "",
      "Please ensure you have provided us with the complete instructions explicitly. If you need to clarify anything, please write in this box"
    );
  };

  if (!user.email_verified) {
    return <ErrorPage5 />;
  }

  return (
    <div>
      {/* <h1>Order Details</h1>
      <br /> */}
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        onSubmit={formik.handleSubmit}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="form-group col-12">
              <label className="font-size-h4">
                <b>Topic</b>
              </label>
              <input
                className={`form-control h-auto py-5 px-6 font-size-h6 font-weight-bold ${getInputClasses(
                  "topic"
                )}`}
                type="text"
                name="topic"
                aria-label="Default select example"
                placeholder="Enter topic of the document"
                onChange={(e) => setTopic(e.target.value)}
                {...formik.getFieldProps("topic")}
              />

              {formik.touched.topic && formik.errors.topic ? (
                <small className="form-text text-danger">
                  {formik.errors.topic}
                </small>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Select Subject</b>
              </label>
              <select
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "subject"
                )}`}
                value={currentSubject}
                name="subject"
                aria-label="Default select example"
                onChange={(e) => setCurrentSubject(e.target.value)}
                {...formik.getFieldProps("subject")}
              >
                <option value="">Select Subject</option>
                {subjects.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {formik.touched.subject && formik.errors.subject ? (
                <small className="form-text text-danger">
                  {formik.errors.subject}
                </small>
              ) : null}
            </div>

            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Type of Work</b>
              </label>
              <select
                name="type_of_work"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "type_of_work"
                )}`}
                style={{ height: 40 }}
                onChange={(e) => setWorkType(e.target.value)}
                aria-label="Default select example"
                {...formik.getFieldProps("type_of_work")}
              >
                <option value="">Select Work</option>
                {work.map((session, index) => (
                  <option key={index} value={session.id}>
                    {session.name}
                  </option>
                ))}
              </select>
              {formik.touched.type_of_work && formik.errors.type_of_work ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.type_of_work}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Type of Paper</b>
              </label>
              <select
                name="type_of_paper"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "type_of_paper"
                )}`}
                style={{ height: 40 }}
                value={paperType}
                onChange={(e) => setPaperType(e.target.value)}
                aria-label="Default select example"
                {...formik.getFieldProps("type_of_paper")}
              >
                <option value="">Select Type Of Paper</option>
                {paper.map((session, index) => (
                  <option key={index} value={session.id}>
                    {session.name}
                  </option>
                ))}
              </select>
              {formik.touched.type_of_paper && formik.errors.type_of_paper ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.type_of_paper}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <label className="font-size-h4 text-dark">
              <b>Amount of Work</b>
            </label>
          </div>
          <div className="row">
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h6 d-flex align-item-center">
                  <b>No. of words</b>
                  <i
                    className="bi bi-info-circle-fill ml-2 text-hover-primary"
                    onClick={noOfWords}
                  ></i>
                </label>

                <input
                  type={"number"}
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "number_of_words"
                  )}`}
                  style={{ height: 40 }}
                  placeholder={"No. of Words"}
                  name={"number_of_words"}
                  {...formik.getFieldProps("number_of_words")}
                />

                {formik.touched.number_of_words &&
                formik.errors.number_of_words ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.number_of_words}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h6 d-flex align-item-center">
                  <b>No. of pages</b>
                  <i
                    className="bi bi-info-circle-fill ml-2 text-hover-primary"
                    onClick={noOfPages}
                  ></i>
                </label>

                <input
                  type={"number"}
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "number_of_pages"
                  )}`}
                  placeholder={"No. of Pages"}
                  style={{ height: 40 }}
                  name={"number_of_pages"}
                  {...formik.getFieldProps("number_of_pages")}
                />

                {formik.touched.number_of_pages &&
                formik.errors.number_of_pages ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.number_of_pages}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h6 d-flex align-item-center">
                  <b>No. of slides </b>{" "}
                  <i
                    className="bi bi-info-circle-fill ml-2 text-hover-primary"
                    onClick={noOfSlides}
                  ></i>
                </label>

                <input
                  type={"number"}
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "number_of_slides"
                  )}`}
                  placeholder={"No. of Slides"}
                  style={{ height: 40 }}
                  name={"number_of_slides"}
                  {...formik.getFieldProps("number_of_slides")}
                />

                {formik.touched.number_of_slides &&
                formik.errors.number_of_slides ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.number_of_slides}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Academic Level</b>
              </label>
              <select
                name="academic_level"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "academic_level"
                )}`}
                style={{ height: 40 }}
                value={academicLevel}
                onChange={(e) => setAcademicLevel(e.target.value)}
                aria-label="Default select example"
                {...formik.getFieldProps("academic_level")}
              >
                <option value="">Select Academic Level</option>
                {academic.map((session, index) => (
                  <option key={index} value={session.id}>
                    {session.name}
                  </option>
                ))}
              </select>
              {formik.touched.academic_level && formik.errors.academic_level ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.academic_level}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Grammerly Report</b>
              </label>
              <select
                name="grammerly_report"
                className={`form-control h-auto py-5 px-6`}
                style={{ height: 40 }}
                value={grammerlyReport}
                onChange={(e) => grammerlySourceHandler(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Select Grammerly Report</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              {grammerlyError && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    Please select Grammerly Report
                  </div>
                </div>
              )}
            </div>
            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Turnitin Report</b>
              </label>
              <select
                name="turnitin_report"
                className={`form-control h-auto py-5 px-6`}
                style={{ height: 40 }}
                value={turnitinReport}
                onChange={(e) => turnitinSourceHandler(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Select Turnitin Report</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              {turnitinError && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    Please select Turnintin Report
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Sources Reuired</b>
                </label>

                <input
                  type={"number"}
                  value={sourceWork}
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "sources_required"
                  )}`}
                  placeholder={"Enter number of sources"}
                  name="sources_required"
                  onChange={(e) => setSourceWork(e.target.value)}
                  aria-label="Default select example"
                  {...formik.getFieldProps("sources_required")}
                />

                {formik.touched.sources_required &&
                formik.errors.sources_required ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.sources_required}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Specific Sources Reuired</b>
                </label>
                <select
                  name="specific_sources_required"
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "specific_sources_required"
                  )}`}
                  style={{ height: 40 }}
                  value={specificSource}
                  onChange={(e) => setSpecificSource(e.target.value)}
                  aria-label="Default select example"
                  {...formik.getFieldProps("specific_sources_required")}
                >
                  <option value="">Select Specific Source</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  {/* {paper.map((session, index) => (
              <option key={index} value={session.id}>
                {session.name}
              </option>
            ))} */}
                </select>
                {formik.touched.specific_sources_required &&
                formik.errors.specific_sources_required ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.specific_sources_required}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Soft Copy of Sources</b>
                </label>
                <select
                  name="soft_copies_of_sources"
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "soft_copies_of_sources"
                  )}`}
                  value={softCopySource}
                  onChange={(e) => setSoftCopySource(e.target.value)}
                  aria-label="Default select example"
                  {...formik.getFieldProps("soft_copies_of_sources")}
                >
                  <option value="">Select Soft Copies of Sources</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  {/* {paper.map((session, index) => (
              <option key={index} value={session.id}>
                {session.name}
              </option>
            ))} */}
                </select>
                {formik.touched.soft_copies_of_sources &&
                formik.errors.soft_copies_of_sources ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.soft_copies_of_sources}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Table of Contents</b>
              </label>
              <select
                name="table_of_content"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "table_of_content"
                )}`}
                style={{ height: 40 }}
                value={tableOfContent}
                onChange={(e) => setTableOfContent(e.target.value)}
                aria-label="Default select example"
                {...formik.getFieldProps("table_of_content")}
              >
                <option value="">Select of Table of content</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              {formik.touched.table_of_content &&
              formik.errors.table_of_content ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.table_of_content}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Spacing</b>
                <i
                  className="bi bi-info-circle-fill ml-2 text-hover-primary"
                  onClick={spacingDialog}
                ></i>
              </label>
              <select
                name="spacing"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "spacing"
                )}`}
                value={spacing}
                onChange={(e) => setSpacing(e.target.value)}
                aria-label="Default select example"
                {...formik.getFieldProps("spacing")}
              >
                <option value="">Select spacing</option>
                <option value="single">Single</option>
                <option value="double">Double</option>
              </select>
              {formik.touched.spacing && formik.errors.spacing ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.spacing}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group col-md-4">
              <label className="font-size-h4">
                <b>Paper Format</b>
              </label>
              <select
                name="paper_format"
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "paper_format"
                )}`}
                style={{ height: 40 }}
                value={paperFormat}
                onChange={(e) => setPaperFormat(e.target.value)}
                aria-label="Default select example"
                {...formik.getFieldProps("paper_format")}
              >
                <option value="">Select Paper Format</option>
                <option value="APA">APA</option>
                <option value="MLA">MLA</option>
                <option value="Harvard">Harvard</option>
                <option value="Chicago/Turabian">Chicago/Turabian</option>
                <option value="Stirling Harvard">Stirling Harvard</option>
                <option value="GCU Harvard">GCU Harvard</option>
                <option value="OSCOLA">OSCOLA</option>
                <option value="Other">Other</option>
              </select>
              {formik.touched.paper_format && formik.errors.paper_format ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.paper_format}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Charts and Diagrams</b>
                  <i
                    className="bi bi-info-circle-fill ml-2 text-hover-primary"
                    onClick={chartsDialog}
                  ></i>
                </label>

                <input
                  type={"number"}
                  value={chartsAndDiagram}
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "charts_and_diagram"
                  )}`}
                  placeholder={"Charts and Diagrams"}
                  name={"charts_and_diagram"}
                  onChange={(e) => setChartsAndDiagram(e.target.value)}
                  {...formik.getFieldProps("charts_and_diagram")}
                />

                {formik.touched.charts_and_diagram &&
                formik.errors.charts_and_diagram ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.charts_and_diagram}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Abstract Page</b>
                </label>
                <select
                  name="abstract_page"
                  className={`form-control h-auto py-5 px-6`}
                  style={{ height: 40 }}
                  value={abstractSource}
                  aria-label="Default select example"
                  onChange={(e) => {
                    abstracSourceHandler(e.target.value);
                  }}
                >
                  <option value="">Select Abstract Page</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {abstractError && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      Please select abstract_page
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Appendices</b>
                </label>
                <select
                  name="appendices"
                  className={`form-control h-auto py-5 px-6 `}
                  style={{ height: 40 }}
                  value={appendiceSource}
                  onChange={(e) => appendicesSourceHandler(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="">Select Appendices</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {appendicesError && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      Please select appendices_page
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="description" className="font-size-h4">
                <b>Description</b>
                <i
                  className="bi bi-info-circle-fill ml-2 text-hover-primary"
                  onClick={descriptionDialog}
                ></i>
              </label>
              <textarea
                className={`form-control h-auto py-5 px-6 ${getInputClasses(
                  "description"
                )}`}
                placeholder="Description"
                style={{ display: "flex", width: "100%" }}
                id="w3review"
                name="description"
                rows="8"
                cols="50"
                {...formik.getFieldProps("description")}
              ></textarea>
              {formik.touched.description && formik.errors.description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.description}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group fv-plugins-icon-container d-flex">
            <div className="form-group col-md-6">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Select Submission Dealine </b>
                </label>
                <select
                  name="submission"
                  className={`form-control h-auto py-5 px-6 `}
                  style={{ height: 40 }}
                  value={submissionDeadlineType}
                  onChange={(e) => setSubmissionDeadlineType(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="">Select Dealine Type</option>
                  <option value="Normal">Normal</option>
                  <option value="Urgent">Urgent</option>
                </select>
                {appendicesError && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      Please select appendices_page
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group col-md-6">
              <div className="form-group">
                <label className="font-size-h4">
                  <b>Deadline {submissionDeadlineType=='Urgent'?'Hours':submissionDeadlineType=='Normal'?'Days':'Days'} </b>
                </label>

                <input
                  type={"number"}
                  value={deadline}
                  className={`form-control h-auto py-5 px-6 ${getInputClasses(
                    "deadline"
                  )}`}
                  placeholder={submissionDeadlineType=='Urgent'?'Deadline Hours':submissionDeadlineType=='Normal'?'Deadline Days':'Deadline Days'}
                  name={"deadline"}
                  onChange={(e) => setDeadline(e.target.value)}
                  
                />
              </div>
            </div>
          </div>

          {inputList.map((x, i) => {
            return (
              <div className="row align-items-center">
                <div className="form-group col-10 col-md-10 ">
                  <input
                    className="form-control h-auto py-4 px-6"
                    name="requirement_file"
                    type={"file"}
                    // value={x.requirement_file}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>

                {inputList.length !== 1 && (
                  <div className="form-group col-md-1">
                    <button
                      className="btn btn-primary mr-3 px-6"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </button>
                  </div>
                )}
                {inputList.length - 1 === i && (
                  <div className="form-group col-md-1 ">
                    <button
                      className=" btn btn-primary  px-4"
                      onClick={handleAddClick}
                    >
                      Add more
                    </button>
                  </div>
                )}
              </div>
            );
          })}

          {/* <div className='form-group col-md-12'>
            <div className='form-group'>
              <label htmlFor='description' className='mt-6'>
                <b>Files</b>
              </label>
              <input type='file' name='file' className='ml-4' onChange={(e) => setFile(e.target.files)} />
            </div>
          </div> */}
          {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
          {/* </div> */}

          <div className="form-group col-md-12">
            <div className="form-group">
              {loading ? (
                <Spinner
                  variant="primary"
                  animation="border"
                  role="status"
                ></Spinner>
              ) : (
                <button
                  type="submit"
                  className="btn btn-success mt-6"
                  disabled={
                    grammerlyError ||
                    turnitinError ||
                    appendicesError ||
                    abstractError
                      ? true
                      : false
                  }
                >
                  Place Order
                </button>
              )}
              <p>{msg}</p>
            </div>
          </div>
        </div>
      </form>
      <div className="row">
        <Modal show={modalShow} onHide={modalClose}>
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="font-size-h5">{modalMessage}</div>
            <div className="font-size-p">{modalDescription}</div>
            <div className="font-size-h5">{modalMessage2}</div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={modalClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
