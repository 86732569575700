/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from 'react';

// const localStorageActiveTabKey = "builderActiveTab";

export function About() {
  return (
    <div>
      <h1>
          Terms & Conditions Page
      </h1>
    </div>
  );
}
