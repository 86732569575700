import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import Spinner from "react-bootstrap/Spinner";
import "./client.style.css";

export default function ClientPage() {
  const authtoken = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);

  const [clientInfo, setClientInfo] = useState([]);

  useEffect(() => {
    getAllClientInfo();
  }, []);

  const getAllClientInfo = () => {
    setLoading(true);
    fetch(`${baseUrl}/all-clients-info`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClientInfo(data.payload.clients);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      
        {/* <AdvanceTablesWidget1 className="card-stretch gutter-b" />  */}
        <div className={"card card-custom card-stretch gutter-b"}>
          {/* begin::Body */}
          <div className="card-body py-0">
            {/* begin::Table */}
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_1"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    {/* <th>Last Name</th> */}
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh",
                      width: "70vw",
                      position: "absolute",
                    }}
                  >
                    <Spinner animation="border" variant="dark" />
                  </div>
                ) : (
                 <>
                    {clientInfo.map((item, index) => (
                      <tr key={index}>
                        <td className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {item.id}
                        </td>
                        <td className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {item.name}
                        </td>
                        {/* <td style={{ color: 'black' }} className='text_align_start'>
                {item.last_name}
              </td> */}
                        <td className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {item.email}
                        </td>
                      </tr>
                    ))}
                 </>
                )}
                 </tbody>
              </table>
            </div>
          </div>
        </div>
      
    </>
  );
}
