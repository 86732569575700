/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";


const localStorageActiveTabKey = "builderActiveTab";

export function Policy() {
    

    return (
        <>
            
                <p>
                  Policy Page
                </p>
          
            
        </>
    );
}
