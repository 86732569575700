
import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
// import './showorder.style.css';
import { Link, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export default function ShowOrder() {
  const authtoken = localStorage.getItem("userToken");

  const [showOrder, setShowOrder] = useState();
  const [showOrderList, setShowOrderList] = useState();
  const [searchText,setSearchText]=useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }

  useEffect(() => {
    OrderData();
  }, []);

  const OrderData = () => {
    setLoading(true);
    fetch(`${baseUrl}/order`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setShowOrder(data.payload);
        setShowOrderList(data.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const ShowOrder = (id) => {
    history.push(`/order/show/${id}`);
  };

  const deleteOrder = (id) => {
    console.log(id);
    fetch(`${baseUrl}/delete-order/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        OrderData();
        // setShowOrder([...data.payload]);
        // toast('Order placed Successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };



  function onchangeSearchHandler (text){
    // console.log(text)
    setSearchText(text);
    let tempArr = showOrder.filter(item=>{
      return text.length>0 ?item.code_no.includes(text)|| item.subject.name.includes(text) || item.type_of_work.name.includes(text) || item.type_of_paper.name.includes(text) :true;
    })
    setShowOrderList(tempArr);
  }

  // console.clear()
  console.log(showOrder?.length);
  return (
    <>
      
      <div className={"card card-custom card-stretch gutter-b"}>
        {/* begin::Body */}
        <div className="card-body py-0">
        <div className="col-md-3 float-right mt-5">
           
          <input type="text" className="form-control" name="searchText" placeholder="Search"  onChange={e=>onchangeSearchHandler(e.target.value)} />
          
          </div>
          {/* begin::Table */}
          <div className="table-responsive">
            <table
            className="table table-vertical-center mt-5"
              id="kt_advance_table_widget_1"
            >
             <thead className="thead-dark ">
                <tr>
                  <th>Order ID</th>
                  <th>Subject</th>
                  <th>Type of Work</th>
                  <th>Type of Paper</th>
                  <th>Sources Required</th>
                  <th>Order Status</th>
                  <th>Payment Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70vh",
                    width: "70vw",
                    position: "absolute",
                  }}
                >
                  <Spinner animation="border" variant="dark" />
                </div>
              ) : (
              <>
                  {showOrderList?.map((order, index) => (
                    <tr key={index}>
                       <td>
                     <Link
                          to={user.role == "Client"? `/ViewOrder/${order.id}` : `/order/show/${order.id}`}
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {order.code_no}
                          </Link>
                       
                      </td>
                      <td className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                        
                          {order.subject?.name}
                        
                      </td>
                      
                      <td className="text-dark-75 text-hover-primary mb-1 font-size-lg">
                        {order.type_of_work?.name}
                      </td>

                      <td className="text-dark-75 text-hover-primary mb-1 font-size-lg">
                        {order.type_of_paper?.name}
                      </td>

                      <td className=" text-hover-primary mb-1 font-size-lg text-center">
                        {order.sources_required}
                      </td>
                      <td
                        className={
                          order?.order_status?.name === "NOT ASSIGNED"
                            ? "text-warning  font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            : "text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        }
                      >
                        {order?.order_status?.name}
                      </td>
                      <td className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {order.payment_status_id == 2 ? (
                          <>
                            <span className="text-danger font-weight-bolder  mb-1 font-size-lg">
                              Not Paid
                            </span>
                          </>
                        ) : (
                          <span className="text-danger font-weight-bolder  mb-1 font-size-lg">
                            Paid
                          </span>
                        )}
                      </td>

                      <td>
                        <div className="row">
                        <a
                          onClick={() => {
                            ShowOrder(order.id);
                          }}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Visible.svg"
                              )}
                            ></SVG>
                          </span>
                        </a>

                        <a
                            onClick={() => deleteOrder(order.id)}
                          className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Files/Deleted-file.svg"
                              )}
                            ></SVG>
                          </span>
                        </a>
                        </div>
                      
                      </td>
                    </tr>
                  ))}
                </>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

   
      {/* <Paper className="p-5">
        <div className="row justify-content-center p-5">
          <h1 className="pl-5 align-center">User Orders</h1>
        </div>
        <Table responsive>
          <thead>
            <tr id="tbl">
              <th>Order ID</th>
              <th>Name</th>
              <th>Order</th>
              <th>Action</th>
            </tr>
          </thead>

          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                position: "absolute",
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <tbody>
              {showOrder?.map((item, index) => (
                <tr id="tbl" key={index}>
                  <td style={{ color: "black" }} className="text_align_start">
                    {item.id}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {item?.description ? item.description : "No description"}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {item.id}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    <div style={{ display: "inline-flex", gap: "12px" }}>
                      <button
                        className="btn btn-xs btn-primary"
                        onClick={() => {
                          ShowOrder(item.id);
                        }}
                      >
                        View
                      </button>
                      <button
                        className="btn btn-xs btn-danger"
                        onClick={() => deleteOrder(item.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </Paper> */}
    </>
  );
}
