import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { useDispatch } from 'react-redux';
import { baseUrl } from '../../../../services/config';
import { Colors } from '../../../../constants/Colors';

const initialValues = {
  email: '',
  password: '',
};

function Login(props) {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const user = {
        email: values.email,
        password: values.password,
      };
      fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      })
        .then((resp) => {
          resp.json().then((user) => {
            if (user.metadata.success) {
              console.log(user);
              localStorage.setItem('userDetails', JSON.stringify(user.payload.user));
              localStorage.setItem('userToken', user.payload.token);

              disableLoading();

              dispatch({
                type: 'ADD_USER',
                payload: {
                  isAuthenticated: true,
                  authtoken: user.payload.token,
                  user: user.payload.user,
                },
              });
              console.log(values);
            } else {
              setStatus(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_LOGIN',
                })
              );
              console.log('Error');
              disableLoading();
              setSubmitting(false);
            }
          });
        })

        .catch((err) => {
          console.log(err);
          seterror('Something went wrong !');
          disableLoading();
          setSubmitting(false);
        });
    },
  });



  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1 text-white'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold text-white'>Enter your Email and password</p>
      </div>
      {/* end::Head */}

      <h3 style={{ color: 'red' }}>{error}</h3>

      {/*begin::Form*/}
      <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
        {formik.status ? (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Email'
            type='email'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
            name='email'
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container password_div'>
          <input
            placeholder='Password'
            type={passwordShown ? "text" : "password"} 
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
            name='password'
            {...formik.getFieldProps('password')}
          />
           <i className="bi bi-eye-slash password-icon" id="togglePassword" onClick={togglePassword}></i>
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <Link to='/auth/forgot-password' className=' text-hover-primary my-3 mr-2 text-white' id='kt_login_forgot'>
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
          </Link>
          <button
            id='kt_login_signin_submit'
            style={{background:`${Colors.primary}`}}
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn font-weight-bold px-9 d-flex justify-content-center`}
          >
            <span className='text-white'>Sign In</span>
            {loading && <span className='ml-3 spinner spinner-white py-4'></span>}
          </button>
        </div>
      </form>
      <div className='text-center mb-15 mb-lg-0 flex-column-auto justify-content-center'>
        <span className='font-weight-bold text-white'>Don't have an account yet?</span>
        <Link to='/auth/registration' className='font-weight-bold ml-2' id='kt_login_signup'>
          Sign Up!
        </Link>
      </div>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
