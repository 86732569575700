import React, { useCallback, useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { Container, Col, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import Dropdown from "react-bootstrap/Dropdown";
import InputLabel from "@material-ui/core/InputLabel";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import "./vieworder.css";
import axios from "axios";
import CheckoutForm from "../../../Components/Payments/Payments";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import { Rating } from "@material-ui/lab";
import Axios from "axios";
import Countdown from "react-countdown";
import CountdownTimer from "../../../Components/Timer/CountdownTimer";
import { Colors } from "../../../constants/Colors";
import { Demo2Dashboard } from "../../../_metronic/_partials/dashboards/Demo2Dashboard";

const ViewOrder = (props) => {
  const history = useHistory();

  const authtoken = localStorage.getItem("userToken");

  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [deliverfile, setDeliverFile] = useState([]);
  const [deliverfiles, setDeliverFiles] = useState([]);
  const [downloadfile, setDownloadFile] = useState([]);
  const [orderfiles, setOrderfiles] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const [showRating, setShowRating] = useState(false);
  const [body, setBody] = useState();
  const [customerServiceRating, setCustomerServiceRating] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [messageDialog, setMessageDialog] = useState(false);
  const handleMessageClose = () => setShowMessage(false);
  const [extentionTime, setExtentionTime] = useState("");

  const [acceptOrderDialog, setAcceptOrderDialog] = useState(false);
  const [acceptMessage, setAcceptMessage] = useState(false);
  const handleAcceptOrder = () => setAcceptOrderDialog(false);

  var count = 1;
  const handleShowPayment = (v_id) => {
    console.log("Voucher Id: " + v_id);
    const data = {
      payment_voucher_id: v_id,
    };

    console.log("Voucher ID", data);
    setVoucherId(v_id);
    fetch(`${baseUrl}/payment_create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())

      .then((resp) => {
        console.log(resp);
        if (resp.metadata.success) {
          console.log(resp);
          console.log("Secret Key: " + resp.payload.clientSecret);
          setclientSecret(resp.payload.clientSecret);
          setShowPayment(true);
        } else {
          alert(resp);
        }
      })
      .catch((err) => {
        console.log("this is after", err);
      });
  };

  const [attachorderrevisionfiles, setOrderRevisionFile] = useState([]);
  const [description, setOrderDescription] = useState("");
  const [paymentvoucher, setPaymentVoucher] = useState([]);

  const [clientSecret, setclientSecret] = useState("");
  const [voucherid, setVoucherId] = useState("");
  const onchangeDescription = (val) => {
    setOrderDescription(val);
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  const getAllOrders = () => {
    setLoading(true);
    fetch(`${baseUrl}/show-order/${props.match.params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Order Detail", data.payload);
        setPaymentVoucher(data.payload.payment_voucher);
        setOrder(data.payload);
        setLoading(false);
        setOrderfiles(data?.payload?.order_attachment_files);
        setDeliverFiles(data?.payload?.order_attachment_files);
        console.log("Delivered files", data?.payload?.order_attachment_files);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const ShowOrder = (id) => {
    history.push(`/order/show/${id}`);
    console.log(id);
  };

  const revisionOrder = (id) => {
    let data = {
      status: 7,
    };
    fetch(`${baseUrl}/order/status-update/${order.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("success");
        getAllOrders();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnSubmit = () => {
    setLoading(true);
    const data = new FormData();
    data.append("order_id", order.id);
    data.append("requirement_file[0][file]", attachorderrevisionfiles);
    data.append("description[0]", description);

    // console.log('form data', data)

    axios
      .post(`${baseUrl}/customer/order_revision`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        console.log("response revision order", res.data);
        setShow(false);
        setLoading(false);
        revisionOrder();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const DownloadFile = (id) => {
    console.log("this is id", id);
    axios
      .get(`${baseUrl}/order/requirement-file/download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        console.log("download file", res);
        setDownloadFile(res.data);
        var element = document.createElement("a");
        var file = new Blob([res.data], {
          type:
            res.data.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? ".docx"
              : res.data.type,
        });
        element.href = URL.createObjectURL(file);
        element.download =
          res.data.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? "order_file.docx"
            : res.data.type;
        element.click();
        // console.log("url is", url);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Accept Order

  const acceptOrder = (id) => {
    let data = {
      order_id: order.id,
    };
    fetch(`${baseUrl}/customer/order_close`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log("success");
        getAllOrders();
        setShowRating(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlerRating = async () => {
    console.log(props.match.params.id);
    let data = {
      order_id: props.match.params.id,
      title: "Title",
      body: body,
      customer_service_rating: customerServiceRating,
    };

    await Axios.post(`${baseUrl}/feedback`, data, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        console.log(res);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRating = () => {
    setShowRating(false);
  };

  const Completionist = () => <span>Time is Over!</span>;

  const convertTime = (orderTime) => {
    let time = parseInt(orderTime);
    time = time * 60 * 60 * 1000;
    console.log(time);
    return time;
  };

  if (order?.total_hours_of_duration) {
    var DAYS_IN_MS = order?.total_hours_of_duration * 60 * 60 * 1000;
    var NOW_IN_MS = new Date().getTime();

    var dateTimeAfter = NOW_IN_MS + DAYS_IN_MS;
  }

  const submitExtention = () => {
    const fdata = new FormData();
    fdata.append("order_id", order?.id);
    fdata.append("additional_hours", extentionTime);

    Axios.post(`${baseUrl}/extend_order_duration`, fdata, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false)
      });
  };

  const submitExtentionApprove = () => {
    const fdata = new FormData();
    fdata.append("order_id", order?.id);

    Axios.post(`${baseUrl}/accept_extend_order_duration`, fdata, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false)
      });
  };

  return (
   <>
      <div className="card-body p-0">
    <div className="container-fluid">
    <div className="row ">
      <div className="col col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <div className="row m-0 bg-dark rounded-top">
          <div className="col px-8 py-6 mr-8 d-flex justify-content-between">
            <div className="font-size-h5 font-weight-bolder text-light ">
              Order Details:
            </div>
            <div className="font-size-h5 font-weight-bolder text-light ">
            
              {order?.total_hours_of_duration && (
                <CountdownTimer targetValue={dateTimeAfter} />
              )}
            </div>
          </div>
        </div>
        <div className="card-spacer bg-white card-rounded flex-grow-1">
         
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                position: "absolute",
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <>
              {/* begin::row */}

              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Subject:
                  </div>
                  <div className="font-size-h6 font-weight-bolder">
                    {order.subject?.name}
                  </div>
                </div>
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Order Id:
                  </div>
                  <div className="font-size-h6 font-weight-bolder">
                    {order.code_no}
                  </div>
                </div>
              </div>

              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Topic:
                  </div>
                  <div className="font-size-h4 font-weight-bolder">
                    {order.topic}
                  </div>
                </div>
              </div>

              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Type of work:
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.type_of_work?.name}
                  </div>
                </div>
                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Type of Paper:
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.type_of_paper?.name}
                  </div>
                </div>
              </div>
             
              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Paper Format
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.paper_format}
                  </div>
                </div>
                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Academic Level
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.academic_level?.name}
                  </div>
                </div>
              </div>
              

              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    No. of Words:
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.number_of_words}
                  </div>
                </div>

                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    No. of Pages
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.number_of_pages}
                  </div>
                </div>
              </div>

              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    No. of Slides:
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.number_of_slides}
                  </div>
                </div>

                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Sources Required
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.sources_required}
                  </div>
                </div>
              </div>

              
              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Specific Sources Required
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.specific_sources_required}
                  </div>
                </div>
                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Soft copies of Sources
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.soft_copies_of_sources}
                  </div>
                </div>
              </div>
             
              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Graph/Chart
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.charts_and_diagram}
                  </div>
                </div>
                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Table of Contents Required
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.table_of_contant}
                  </div>
                </div>
              </div>
             
              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Abstract Page Required
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.abstract_page}
                  </div>
                </div>
                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Appendices Required
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.appendices}
                  </div>
                </div>
              </div>
           
              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Grammarly Report Required
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.grammarly_report_required}
                  </div>
                </div>

                <div className="col px-8 py-6">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Turnitin Report Required
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.turnitin_report_required}
                  </div>
                </div>
              </div>
         

              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Description:
                  </div>
                  <div className="font-size-p font-weight-bolder mt-3">
                    {order.description}
                  </div>
                </div>
              </div>

            
              <div className="row m-0">
                <div className="col px-8 py-6 mr-8">
                  <div className="font-size-sm text-muted font-weight-bold">
                    Attachment File
                  </div>
                  <div className="font-size-h6 font-weight-bolder">
                    {orderfiles ? (
                      <div className="row col-md-12 col-sm-12">
                        {orderfiles.map((item, index) =>
                          item.file_type_id != 4 &&
                          item.file_type_id != 5 ? (
                            <a
                              key={index}
                              className="ml-1"
                              onClick={() => DownloadFile(item.id)}
                            >
                          
                              <img
                                src={toAbsoluteUrl(
                                  "/media/users/download.png"
                                )}
                                width={"45px"}
                                height={"45px"}
                              />
                            </a>
                          ) : null
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
           

              <Modal show={showRating} onHide={handleRating}>
                <Modal.Header closeButton>
                  <Modal.Title>Rating</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="font-size-h3">
                    Give your feedback related to this order
                  </div>
                  <div className="container">
                
                    <div className="row">
                      <input
                        type={"text"}
                        name="body"
                        placeholder={"Comment"}
                        onChange={(e) => setBody(e.target.value)}
                      />
                    </div>
                  </div>
               
                  <div className="row d-flex justify-content-center">
                    <Rating
                      onChange={(item) =>
                        setCustomerServiceRating(item.target.value)
                      }
                      className={"align-items-center"}
                    />
                  </div>
                  <div className="row d-flex justify-content-center">
                   
                    <button className="btn-danger mr-5">Cancel</button>
                    <button onClick={handlerRating}>Submit</button>
                   
                  </div>
                </Modal.Body>
              </Modal>

              <Modal show={showPayment} onHide={handleClosePayment}>
                <Modal.Header closeButton>
                  <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <CheckoutForm
                    closeModal={handleClosePayment}
                    clientSecret={clientSecret}
                    voucherid={voucherid}
                  />
           
                </Modal.Body>
              
              </Modal>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Order Revision</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        onChange={(e) =>
                          onchangeDescription(e.target.value)
                        }
                        rows={3}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
    
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6 d-flex align-items-center">
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            style={{
                              // fontSize: "1.1rem",
                              // fontWeight: "500",
                              color: "black",
                            }}
                          >
                            Attachment File
                          </InputLabel>
                       
                        </div>
                        <div className="col-md-6">
                          <input
                            className=""
                            name="file"
                            type={"file"}
                            onChange={(e) =>
                              setOrderRevisionFile(e.target.files[0])
                            }
                          />
                        </div>
                      
                      </div>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  {loading ? (
                    <Spinner
                      variant="primary"
                      animation="border"
                      role="status"
                    ></Spinner>
                  ) : (
                    <Button variant="primary" onClick={OnSubmit}>
                      Submit
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
      <div className="col col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-5">
        <div className="row">
          <div className="col ">
            <div className="row">
              <div className="col ">
                <div
                  className="bg-dark d-flex justify-content-center rounded-top"
                  style={{ height: "100px", minHeight: "100px" }}
                >
                  <div className="font-size-h5 text-white font-weight-bold d-flex align-items-center">
                    Payment Details
                  </div>
                </div>
                {paymentvoucher?.length > 0 ? (
                  paymentvoucher.map((item, index) =>
                    item.voucher_id ? (
                      <>
                        {/* begin::Stats */}
                        <div
                          className="card-spacer bg-white rounded-bottom flex-grow-1"
                          key={index}
                        >
                          {/* begin::Row */}
                          <div className="row m-0">
                            <div>
                              <div className="col px-8 py-6 mr-8">
                                <div className="font-size-sm text-muted font-weight-bold">
                                  Voucher ID:
                                </div>
                                <div className="font-size-h5 font-weight-bolder">
                                  {item.voucher_id}
                                </div>
                              </div>
                              <div className="col px-8 py-6 mr-8">
                                <div className="font-size-sm text-muted font-weight-bold">
                                  Payment Price:
                                </div>
                                <div className="font-size-h5 font-weight-bolder">
                                  {item.charges
                                    ?"$"+ item.charges / 100
                                    : "There is no payment bid yet"}
                                </div>
                              </div>
                            </div>
                          </div>
                          {item.status == 2 ? (
                            <div className="row m-0">
                              <div
                                style={{
                                  display: "inline-flex",
                                  gap: "12px",
                                }}
                              >
                                <button
                                  className="btn btn-xs btn-primary"
                                  onClick={() => handleShowPayment(item.id)}
                                >
                                  Payment
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* end::Stats */}
                      </>
                    ) : null
                  )
                ) : (
                  <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                    <div className="row m-0">
                      <div className="col px-8 py-6 mr-8">
                        <div className="font-size-h5 font-weight-bolder">
                          There is no payment bid yet
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col ">
            <div className="row mt-5">
              <div className="col ">
                <div
                  className="bg-danger d-flex justify-content-center rounded-top"
                  style={{ height: "100px", minHeight: "100px" }}
                >
                  <div className="font-size-h5 text-white font-weight-bold d-flex align-items-center">
                    Review
                  </div>
                </div>
                {order?.reviews?.length > 0 ? (
                  order?.reviews?.map((item, index) => (
                    <>
                      {/* begin::Stats */}
                      <div
                        className="card-spacer bg-white rounded-bottom flex-grow-1"
                        key={index}
                      >
                        {/* begin::Row */}
                        <div className="row m-0">
                          <div>
                            <div className="col px-8 py-6 mr-8">
                              <div className="font-size-sm text-muted font-weight-bold">
                                Comment
                              </div>
                              <div className="font-size-h5 font-weight-bolder">
                                {item.body}
                              </div>
                            </div>
                            <div className="col px-8 py-6 mr-8">
                              <div className="font-size-sm text-muted font-weight-bold">
                                Rate:
                              </div>
                              <div className="font-size-h5 font-weight-bolder">
                                <Rating
                                  value={item.customer_service_rating}
                                  draggable={false}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                    <div className="row m-0">
                      <div className="col px-8 py-6 mr-8">
                        <div className="font-size-h5 font-weight-bolder">
                          You are not allowed to review this order yet.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col ">
            <div className="row mt-5">
              <div className="col ">
                <div
                  className="bg-dark d-flex justify-content-center rounded-top"
                  style={{ height: "100px", minHeight: "100px" }}
                >
                  <div className="font-size-h5 text-white font-weight-bold d-flex align-items-center">
                    Duration Extend
                  </div>
                </div>
                <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                  {/* begin::Row */}
                  <div className="row m-0">
                    <div>
                      <div className="col px-8 py-6 mr-8">
                        <div className="font-size-sm text-muted font-weight-bold">
                          Entention in hours
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                          <input
                           
                            className="form-control"
                            type={"number"}
                            value={extentionTime}
                            onChange={(e) =>
                              setExtentionTime(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col px-8 py-6 mr-8">
                        <div className="font-size-h5 font-weight-bolder">
                          <button
                          disabled={extentionTime.length>0?false:true}
                            className="btn btn-dark"
                            onClick={submitExtention}
                          >
                            Extend
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

               
        {
         ( order?.extend_time_requests  && order?.extend_time_requests.length >0) &&  
          <div className="row">
          <div className="col ">
            <div className="row mt-5">
              <div className="col ">
                <div
                  className="bg-dark d-flex justify-content-center rounded-top"
                  style={{ height: "100px", minHeight: "100px" }}
                >
                  <div className="font-size-h5 text-white font-weight-bold d-flex align-items-center">
                    Duration Extend Request
                  </div>
                </div>
                <div className="card-spacer bg-white rounded-bottom flex-grow-1">
                 
                  <div className="row m-0">
                    <div>
                      <div className="col px-8 py-6 mr-8">
                        <div className="font-size-sm text-muted font-weight-bold">
                          Entention in hours
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                         {order?.extend_time_requests[0].additional_hours} Additional Hours
                        </div>
                      </div>
                      <div className="col px-8 py-6 mr-8">
                        <div className="font-size-h5 font-weight-bolder">
                          <button
                          
                            className="btn btn-dark"
                            onClick={submitExtentionApprove}
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
    </div>
    <div className="table-responsive">
      <div className="row m-0">
        <div className="col px-8 py-6 mr-8">
          <div className="font-size-h5 font-weight-bolder">
            Order Delivered
          </div>
        </div>
      </div>
      <table
        className="table table-vertical-center bg-white card-rounded"
        id="kt_advance_table_widget_1"
      >
        <thead className="thead-dark">
          <tr className="text-left">
            <th>#</th>
            <th>Message</th>
            <th className="text-center">Attachment File</th>
            <th className="text-center">Date</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                position: "absolute",
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <>
              {order?.order_status?.id === 7 ? (
                <>
                  {deliverfiles.map((deliverOrderfile, index) =>
                    deliverOrderfile.file_type_id == 4 ||
                    deliverOrderfile.file_type_id == 5 ? (
                      <>
                        <tr key={index}>
                          <td>{"Order Delivered-" + count++}</td>
                          <td>
                            {deliverOrderfile.description
                              .toString()
                              .slice(0, 32) + " "}
                            <a
                              className="text-primary"
                              onClick={() => {
                                setMessageDialog(
                                  deliverOrderfile.description
                                );
                                setShowMessage(true);
                              }}
                            >
                              Show more...
                            </a>
                          </td>
                          <td className="text-center">
                            <a
                              className="row icon_style d-flex justify-content-center"
                              onClick={() =>
                                DownloadFile(deliverOrderfile.id)
                              }
                            >
                              <CloudDownloadIcon fontSize="large">
                                {downloadfile}
                              </CloudDownloadIcon>
                            </a>
                          </td>
                          <td className="text-center">
                            {new Date(deliverOrderfile.created_at)
                              .toLocaleString()
                              .slice(0, 9)}
                          </td>
                          <td className="text-center">
                            {deliverOrderfile.name}
                          </td>
                        </tr>
                      </>
                    ) : null
                  )}
                  <tr>
                    <td colSpan={5}>
                      <div className="row mt-10 order-action d-flex justify-content-center">
                        <div
                          style={{ display: "inline-flex", gap: "12px" }}
                        >
                          {/* <button className="btn btn-xs btn-primary" onClick={acceptOrder}>
                    Accept
                  </button> */}
                          <button
                            className="btn btn-xs btn-primary"
                            onClick={() => setAcceptOrderDialog(true)}
                          >
                            Approve
                          </button>
                          <button
                            onClick={handleShow}
                            className="btn btn-xs btn-danger"
                          >
                            Revision
                          </button>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                              // Chat(order.id);
                              history.push(`/chat/${order.id}`);
                            }}
                          >
                            Chat
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ) : order?.order_status?.id === 4 ? (
                <>
                  {deliverfiles.map((deliverOrderfile, index) =>
                    deliverOrderfile.file_type_id == 4 ||
                    deliverOrderfile.file_type_id == 5 ? (
                      <>
                        <tr key={index}>
                          <td>{"Order Delivered-" + count++}</td>
                          <td>
                            {deliverOrderfile.description
                              .toString()
                              .slice(0, 32) + " "}
                            <a
                              className="text-primary"
                              onClick={() => {
                                setMessageDialog(
                                  deliverOrderfile.description
                                );
                                setShowMessage(true);
                              }}
                            >
                              Show more...
                            </a>
                          </td>
                          <td className="text-center">
                            <a
                              className="row icon_style d-flex justify-content-center"
                              onClick={() =>
                                DownloadFile(deliverOrderfile.id)
                              }
                            >
                              <CloudDownloadIcon fontSize="large">
                                {downloadfile}
                              </CloudDownloadIcon>
                            </a>
                          </td>
                          <td className="text-center">
                            {new Date(deliverOrderfile.created_at)
                              .toLocaleString()
                              .slice(0, 9)}
                          </td>
                          <td className="text-center">
                            {deliverOrderfile.name}
                          </td>
                        </tr>
                      </>
                    ) : null
                  )}
                  <tr>
                    <td colSpan={5}>
                      <div className="row mt-10 order-action d-flex justify-content-center">
                        <div
                          style={{ display: "inline-flex", gap: "12px" }}
                        >
                          {/* <button className="btn btn-xs btn-primary" onClick={acceptOrder}>
                    Accept
                  </button> */}
                          <button
                            className="btn btn-xs btn-primary"
                            onClick={acceptOrder}
                          >
                            Approve
                          </button>
                          <button
                            onClick={handleShow}
                            className="btn btn-xs btn-danger"
                          >
                            Revision
                          </button>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                              // Chat(order.id);
                              history.push(`/chat/${order.id}`);
                            }}
                          >
                            Chat
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ) : order?.order_status?.id === 9 ? (
                <>
                  {deliverfiles.map((deliverOrderfile, index) =>
                    deliverOrderfile.file_type_id == 4 ||
                    deliverOrderfile.file_type_id == 5 ? (
                      <>
                        <tr key={index}>
                          <td>{"Order Delivered-" + count++}</td>
                          <td>
                            {deliverOrderfile.description
                              .toString()
                              .slice(0, 32) + " "}
                            <a
                              className="text-primary"
                              onClick={() => {
                                setMessageDialog(
                                  deliverOrderfile.description
                                );
                                setShowMessage(true);
                              }}
                            >
                              Show more...
                            </a>
                          </td>
                          <td className="text-center">
                            <a
                              className="row icon_style d-flex justify-content-center"
                              onClick={() =>
                                DownloadFile(deliverOrderfile.id)
                              }
                            >
                              <CloudDownloadIcon fontSize="large">
                                {downloadfile}
                              </CloudDownloadIcon>
                            </a>
                          </td>
                          <td className="text-center">
                            {new Date(deliverOrderfile.created_at)
                              .toLocaleString()
                              .slice(0, 9)}
                          </td>
                          <td className="text-center">
                            {deliverOrderfile.name}
                          </td>
                        </tr>
                      </>
                    ) : null
                  )}
                </>
              ) : null}
            </>
          )}
        </tbody>
      </table>
    </div>
  </div>



  <Modal show={showMessage} onHide={handleMessageClose}>
    <Modal.Header closeButton>
      <Modal.Title>Message</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{messageDialog}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleMessageClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>

  <Modal show={acceptOrderDialog} onHide={handleAcceptOrder}>
    <Modal.Header closeButton>
      <Modal.Title>Aceept Order?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure to accept the order</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={acceptOrder}>
        Approve
      </Button>
      <Button variant="secondary" onClick={handleAcceptOrder}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
   </>
   
  );
};

export default ViewOrder;
