import React, { useRef, useState } from "react";
import { Formik, useFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as auth from "../_redux/authRedux";
import { baseUrl } from "../../../../services/config";
import { Colors } from "../../../../constants/Colors";
import 'react-phone-number-input/style.css'

import Axios from "axios";
import PhoneInput from "react-phone-number-input";





const initialValues = {
  name: "",
  // last_name: '',
  email: "",
  password: "",
  confirm_password: "",
};



function Registration(props) {
  const authtoken = localStorage.getItem("userToken");
  const { intl } = props;
  const ref = useRef();
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    last_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    dob: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),

    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirm_password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      const fdata = new FormData();

      fdata.append("first_name",values.first_name);
      fdata.append("last_name",values.last_name);
      fdata.append("dob",values.dob);
      fdata.append("email",values.email);
      fdata.append("password",values.password);
      fdata.append("confirm_password",values.confirm_password);
      fdata.append("mobile",value);

      Axios.post(`${baseUrl}/register`,fdata,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
      })
      // .then((response) => response.json())
      .then((response)=>{
        console.log("Success:", response);
        // if (response.metadata.success === true) {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.payload.user)
          );
          localStorage.setItem("userToken", response.data.payload.token);

          disableLoading();

          dispatch({
            type: "ADD_USER",
            payload: {
              isAuthenticated: true,
              authtoken: response.data.payload.token,
              user: response.data.payload.user,
            },
          });
          console.log(values);
          setRegister(true);
          disableLoading();
        // }
      })
      .catch((e) => {
        setStatus("You are already registered");
        console.log(e)
      })
      .finally(() => {
        disableLoading();
        setSubmitting(false);
      });

      // fetch(`${baseUrl}/register`, {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${authtoken}`,
      //   },
      //   body: JSON.stringify(values),
      // })
      //   .then((response) => response.json())
      //   .then((user) => {
      //     console.log("Success:", values);
      //     if (user.metadata.success === true) {
      //       localStorage.setItem(
      //         "userDetails",
      //         JSON.stringify(user.payload.user)
      //       );
      //       localStorage.setItem("userToken", user.payload.token);

      //       disableLoading();

      //       dispatch({
      //         type: "ADD_USER",
      //         payload: {
      //           isAuthenticated: true,
      //           authtoken: user.payload.token,
      //           user: user.payload.user,
      //         },
      //       });
      //       console.log(values);
      //       setRegister(true);
      //       disableLoading();
      //     }
      //   })
      //   .catch(() => {
      //     setStatus("You are already registered");
      //   })
      //   .finally(() => {
      //     disableLoading();
      //     setSubmitting(false);
      //   });

      console.log(values);
      enableLoading();
      setSubmitting(true);
    },
  });

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };


  const checkAge=(date)=>{
    console.log(date)
    let dob = new Date(date);
    //calculate month difference from current date in time
    let month_diff = Date.now() - dob.getTime();
    
    //convert the calculated difference in date format
    let age_dt = new Date(month_diff); 
    
    //extract year from date    
    let year = age_dt.getUTCFullYear();
    
    //now calculate the age of the user
    let age = Math.abs(year - 1970);
    
    //display the calculated age
    document.write("Age of the date entered: " + age + " years");
    if(age>18){
      return true
    }
    return false;
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1 text-white">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      {/* <Formik
      onSubmit={formik.onSubmit}
            > */}
      {/* {({ handleSubmit, handleChange, setValues, handleBlur, setFieldValue, isSubmitting, isValid, errors, touched, values }) => ( */}
      {/* <Form> */}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container d-flex jusitify-content-space">
          <input
            placeholder="First Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 mr-1 ${getInputClasses(
              "first_name"
            )}`}
            name="first_name"
            {...formik.getFieldProps("first_name")}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.first_name}</div>
            </div>
          ) : null}

          <input
            placeholder="Last Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ml-1 ${getInputClasses(
              "last_name"
            )}`}
            name="last_name"
            {...formik.getFieldProps("last_name")}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.last_name}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container d-flex">
          <input
            placeholder="Date of birth"
            type="text"
            max="2004-08-31"
            ref={ref}
            // onChange={(e) => console.log(e.target.value)}
            onFocus={() => (ref.current.type = "date")}
            onBlur={() => (ref.current.type = "text")}           
            className={`form-control form-control-solid h-auto py-5 px-6 mr-1 date_input ${getInputClasses(
              "dob"
            )}`}
            name="dob"
            {...formik.getFieldProps("dob")}
          />
          {formik.touched.dob && formik.errors.dob ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.dob}</div>
            </div>
          ) : null}



          {/* <input
            placeholder="Phone"
            type="mobile"
            className={`form-control form-control-solid h-auto py-5 px-6 ml-1 ${getInputClasses(
              "mobile"
            )}`}
            name="mobile"
            {...formik.getFieldProps("mobile")}
          />
          {formik.touched.mobile && formik.errors.mobile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.mobile}</div>
            </div>
          ) : null} */}
        </div>
        <div className="form-group fv-plugins-icon-container d-flex">
        {/* <PhoneInput
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}
      style={{background:"#EBEDF3"}}
      containerComponent={"input"}
     /> */}
     {/* <Input
  placeholder="Enter phone number"
  className={`form-control form-control-solid h-auto py-5 px-6 ml-1 ${getInputClasses(
    "mobile"
  )}`}
  value={value}
  onChange={setValue}
  international={true}
  // {...formik.getFieldProps("mobile")}
  
  /> */}
<PhoneInput
 className={`form-control form-control-solid d-flex h-auto py-5 px-6 ml-1`}
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}
      focusInputOnCountrySelection={false}
            
      />
         
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container password_div">
          <input
            placeholder="Password"
            type={passwordShown ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          <i
            className="bi bi-eye-slash password-icon"
            id="togglePassword"
            onClick={togglePassword}
          ></i>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "confirm_password"
            )}`}
            name="confirm_password"
            {...formik.getFieldProps("confirm_password")}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirm_password}
              </div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            className="btn  font-weight-bold px-9  my-3 mx-4"
            style={{
              background: `${(formik.isSubmitting || !formik.isValid) &&
                Colors.primary}`,
            }}
          >
            <span className="text-white">Submit</span>
            {loading && (
              <span className="ml-3 spinner spinner-white py-4"></span>
            )}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
          {register ? (
            <p className="text-success">
              You Are Registered Successfully{" "}
              <Link to="/auth/login" className="font-weight-bold ml-2">
                Login
              </Link>{" "}
            </p>
          ) : null}
        </div>
      </form>
      {/* </Form>
                )}


            </Formik> */}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
