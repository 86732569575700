import React, { useState, useEffect, useRef } from "react";
import { FormControl, Input, IconButton } from "@material-ui/core";
import FlipMove from "react-flip-move";
import SendIcon from "@material-ui/icons/Send";
import { Spinner, Table } from "react-bootstrap";
import "./chat.style.css";
import Message from "../Message/Message";
import { baseUrl } from "../../services/config";
import Pusher from 'pusher-js';
import Axios from "axios";

function ChatPanel(props) {
  const flipRef = useRef();

  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }

  const authtoken = localStorage.getItem("userToken");

  const [order, setOrder] = useState({});
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const pusher = new Pusher('06b2ed98f7ef75566ff0', {
    cluster: 'ap2',
    // encrypted: true
  });

  useEffect(() => {
    getOrderDetails();
    // addResponseMessage('Hi');
    console.log(user.id)

    Pusher.logToConsole = true;
        // const pusher = new Pusher('06b2ed98f7ef75566ff0', {
        //   cluster: 'ap2',
        //   // encrypted: true
        // });
        const channel = pusher.subscribe(`orders.${props.match.params.id}`);
        channel.bind('orderChat', data => {
           console.log("THis Data"+JSON.stringify(data));
           recivesendMessage(data)
        });
  }, []);

  const getOrderDetails = () => {
    fetch(`${baseUrl}/show-order/${props.match.params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrder(data.payload);
        GetAllMessages(data.payload.id);
      })

      .catch((err) => {
        console.log(err);
      });
  };



  // const pusher = new Pusher({
  //   key: '06b2ed98f7ef75566ff0',
  //   cluster: 'ap2',
  //   encrypted: true
  // });

  // app.post('/message', (req, res) => {
  //   const payload = req.body;
  //   pusher.trigger('chat', 'message', payload);
  //   res.send(payload)
  // });



  // Pusher.logToConsole = true;

  //   var pusher = new Pusher('06b2ed98f7ef75566ff0', {
  //     cluster: 'ap2'
  //   });

  //   var channel = pusher.subscribe(`orders.${props.match.params.id}`);
  //   channel.bind('orderChat', function(data) {
  //     alert(JSON.stringify(data));
  //   });


    // var channel = pusher.subscribe(`orders.${props.match.params.id}`);

    // channel.bind('orderChat', function(data) {
    
    //   alert('Received orderChat with message: ' + data.message);
    
    // });
  const GetAllMessages = (id) => {
    const data = {
      order_id: id,
    };
    console.log(data);
    Axios.post(`${baseUrl}/order_messages`, data, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        // console.log(res.data.payload);
        setMessages(res.data.payload.reverse());
        // Pusher.logToConsole = true;
        // // const pusher = new Pusher('06b2ed98f7ef75566ff0', {
        // //   cluster: 'ap2',
        // //   // encrypted: true
        // // });
        // const channel = pusher.subscribe(`orders.${props.match.params.id}`);
        // channel.bind('orderChat', data => {
        //    console.log("THis Data"+JSON.stringify(data));
        //    recivesendMessage(data)
          //  channel.unbind('orderChat')

          //  channel.bind('orderChat', data => {
          //   console.log( "Or Data"+data); // This will fisre.
          //   recivesendMessage(data)
          // });
        // });
        // this.handleTextChange = this.handleTextChange.bind(this);
        // console.log('chat', data)
        // pusher.trigger('chat', 'message', res.data.payload);
        // res.send(res.data.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sendMessage = (e) => {
    console.log(order.id)
    setLoading(true);
    const data = {
      order_id: order.id,
      user_id: user.id,
      message: input,
    };
    // console.log("order IDDDDDD", data);
    Axios.post(`${baseUrl}/message`, data, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        console.log("Response Get====> ",res.data);
        GetAllMessages(order?.id);
        setInput("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const recivesendMessage = (e) => {
    GetAllMessages(e.message.order_id);
    console.log("total Messages===>"+messages);
  };

  return (
    <>
      <div id="container">
        <main>
          <header>
            <div style={{ backgroundColor: "#fff" }}>
              <Table responsive>
                <thead className="tab-head">
                  <tr id="tbl" style={{ color: "white" }}>
                    <th>ID</th>
                    <th>Subject</th>
                    <th>Amount of Work</th>
                    <th>Type of Work</th>
                    <th>Type of Paper</th>
                    <th>Sources Required</th>
                  </tr>
                </thead>

                <tbody>
                  <tr id="tbl">
                    <td className="text_align_start text-dark">
                      {order.code_no}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {order.subject?.name}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {order.amount_of_work}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {order.type_of_work?.name}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {order.type_of_paper?.name}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {order.sources_required}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </header>
          
          <div id="chat">
            <FlipMove ref={flipRef}>
              {messages.map(({ id, message, user_id }) => (
                <Message
                  key={id}
                  message={message}
                  isUser={user.id == user_id ? true : false}
                />
              ))}
            </FlipMove>
          </div>
              
          <footer className="row mt-2">
            <div className="col col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <textarea
                value={input}
                className="form-control"
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message"
              ></textarea>
            </div>
            <div className="col col-sm-2 col-md-2 col-lg-2 col-xl-2 d-flex justify-content-center">
            {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="dark" />
              </div>
              ) : (
              <a className="btn btn-dark h-auto py-5 w-100" onClick={sendMessage}>
                Send
              </a>
              )}
            </div>
          </footer>
        </main>
      </div>
      {/* <div style={{backgroundColor: '#fff'}}>
        <Table responsive>
          <thead>
            <tr id='tbl'>
              <th>ID</th>
              <th>Subject</th>
              <th>Amount of Work</th>
              <th>Type of Work</th>
              <th>Type of Paper</th>
              <th>Sources Required</th>
            </tr>
          </thead>

          <tbody>
            <tr id='tbl'>
              <td style={{ color: 'black' }} className='text_align_start'>
                {order.id}
              </td>
              <td style={{ color: 'black' }} className='text_align_start'>
                {order.subject?.name}
              </td>
              <td style={{ color: 'black' }} className='text_align_start'>
                {order.amount_of_work}
              </td>
              <td style={{ color: 'black' }} className='text_align_start'>
                {order.type_of_work?.name}
              </td>
              <td style={{ color: 'black' }} className='text_align_start'>
                {order.type_of_paper?.name}
              </td>
              <td style={{ color: 'black' }} className='text_align_start'>
                {order.sources_required}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div>
      <FlipMove
        ref={flipRef}
      >

        {messages.map(({ id, message, isUser }) => (

          <Message
            key={id}
            username={'Test'}
            message={message}
            isUser={isUser}

          />

        ))}
      </FlipMove>
      <div className='chat_input row'>
        <div>
          <input value={input} onChange={(e) => setInput(e.target.value)} className='input-text' placeholder='enter message' />
        </div>
        <div style={{ position: 'absolute', right: 100 }}>

          <button
            onClick={sendMessage}
            style={{ border: 'none', backgroundColor: 'transparent' }} type='submit'>
            <SendIcon />
          </button>
        </div>
        <button
          onClick={recivesendMessage}
          style={{ border: 'none', backgroundColor: 'transparent', marginLeft: -20 }} type='submit'>
          Test
        </button>
      </div>
    </div> */}
    </>
  );
}

export default ChatPanel;
