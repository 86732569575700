/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted` : '';
  };
  
  let user;
  if (localStorage.getItem('userDetails')) {
    user = JSON.parse(localStorage.getItem('userDetails'));
    console.log(user)
  }
  
  return (
    <>
      {user.role == 'Admin' ? (
        <>
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          
          <li className={`menu-item ${getMenuItemActive('/admin', true)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/dashboard'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
              </span>
              <span style={{ color: 'white' }} className='menu-text white_color'>Dashboard</span>
            </NavLink>
          </li>
           
          <li className={`menu-item ${getMenuItemActive('/admin', true)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/orders'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
              </span>
              <span style={{ color: 'white' }} className='menu-text white_color'>
                All Orders
              </span>
            </NavLink>
          </li>
            

            <li className={`menu-item ${getMenuItemActive('/admin', true)}`} aria-haspopup='true'>
              <NavLink className='menu-link' to='/clients'>
                <span className='svg-icon menu-icon'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                </span>
                <span style={{ color: 'white' }} className='menu-text white_color'>
                  Clients
                </span>
              </NavLink>
            </li>
            
            <li className={`menu-item ${getMenuItemActive('/admin', true)}`} aria-haspopup='true'>
              <NavLink className='menu-link' to='/weeklyincome'>
                <span className='svg-icon menu-icon'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                </span>
                <span style={{ color: 'white' }} className='menu-text white_color'>
                  Payment History
                </span>
              </NavLink>
            </li>
          </ul>
        </>
      ) : (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className={`menu-item ${getMenuItemActive('/user', true)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/dashboard'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
              </span>
              <span className='menu-text'>Dashboard</span>
            </NavLink>
          </li>

         
          <li className={`menu-item ${getMenuItemActive('/user', true)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/Expert'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
              </span>
              <span className='menu-text'>Hire an Expert</span>
            </NavLink>
          </li>

          <li className={`menu-item ${getMenuItemActive('/user', true)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/history/allorders'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
              </span>
              <span className='menu-text'>Order History</span>
            </NavLink>
          </li>

          <li className={`menu-item ${getMenuItemActive('/user', true)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/weeklyincome'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
              </span>
              <span className='menu-text'>Transaction</span>
            </NavLink>
          </li>

          <li className={`menu-item ${getMenuItemActive('/user', true)}`} aria-haspopup='true'></li>
          {/* <li className={`menu-item menu-item-submenu ${getMenuItemActive('/policy-page', true)}`} aria-haspopup='true' data-menu-toggle='hover'>
            <NavLink className='menu-link menu-toggle' to='/policy-page'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Cap-2.svg')} />
              </span>
              <span className='menu-text'>Privacy & Policy</span>
              
            </NavLink>
          </li> */}

          {/* <li className={`menu-item menu-item-submenu ${getMenuItemActive('/user', true)}`} aria-haspopup='true' data-menu-toggle='hover'>
            <NavLink className='menu-link menu-toggle' to='/about-page'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Box2.svg')} />
              </span>
              <span className='menu-text'>Terms & Conditions</span>
              
            </NavLink>
          </li> */}
          {/*end::1 Level*/}
        </ul>
      )}
    </>
  );
}
