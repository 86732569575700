/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { DropdownMenu2 } from "../../dropdowns";
import { baseUrl } from "../../../../services/config";
import "./dashboard.css";
import Axios from "axios";

export function MixedWidget1({ className }) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  const history = useHistory();

  const authtoken = localStorage.getItem("userToken");

  const [allCount, setAllCount] = useState({});

  const [allorders, setAllOrders] = useState();
  const [orderinprogress, setOrdersInProgress] = useState();
  const [approvedorders, setAprrovedOrders] = useState();
  const [completeorders, setCompleteOrders] = useState();
  const [admincompletedorders, setAdminCompletedOrders] = useState();
  const [usercompletedorders, setUserCompletedOrders] = useState();
  const [rejectedorders, setRejectedOrders] = useState();

  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }

  useEffect(() => {
    async function getFun() {
      await allGetCount();
    }

    getFun();

    // InProgressOrders();
    // AllOrders();
    // ApprovedOrders();
    // DeliverOrders();
    // AdminCompletedOrders();
    // RejectedOrders();

    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  // console.log(authtoken)

  const allGetCount = async () => {
    if (user.role === "Admin") {
      await Axios.get(`${baseUrl}/admin_order_count`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log("Count===>",res.data);
          setAllCount(res.data.payload);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      await Axios.get(`${baseUrl}/client_order_count`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setAllCount(res.data.payload);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
  };

  const AllOrders = () => {
    const data = {
      user_id: user.id,
      order_status_id: 2,
    };
    if (user.role == "Admin") {
      Axios.post(`${baseUrl}/admin/orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setAllOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      Axios.post(`${baseUrl}/customer_orders/${data.user_id}`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setAllOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
  };

  const InProgressOrders = () => {
    const data = {
      user_id: user.id,
      order_status_id: 2,
    };
    if (user.role == "Admin") {
      Axios.post(`${baseUrl}/admin/orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setOrdersInProgress(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setOrdersInProgress(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
  };

  const ApprovedOrders = () => {
    const data = {
      user_id: user.id,
      order_status_id: 2,
    };
    if (user.role == "Admin") {
      Axios.post(`${baseUrl}/admin/orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setAprrovedOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setAprrovedOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
  };

  const DeliverOrders = () => {
    const data = {
      user_id: user.id,
      order_status_id: 7,
    };
    if (user.role == "Admin") {
      Axios.post(`${baseUrl}/admin/orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setCompleteOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setCompleteOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
  };

  const AdminCompletedOrders = () => {
    const data = {
      user_id: user.id,
      order_status_id: 9,
    };
    if (user.role == "Admin") {
      Axios.post(`${baseUrl}/admin/orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setAdminCompletedOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setUserCompletedOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
  };

  const RejectedOrders = () => {
    const data = {
      user_id: user.id,
      order_status_id: 8,
    };
    if (user.role == "Admin") {
      Axios.post(`${baseUrl}/admin/orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setRejectedOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setRejectedOrders(res.data.payload.count);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
  };

  return (
    <>
      {user.role == "Admin" ? (
        <div className="card-spacer ">
          <div className="container">
            <div className="row m-0 justify-content-around">
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-warning py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Clipboard.svg"
                    )}
                  ></SVG>
                </span>
                <a
                  href="/history/order-progess"
                  className="text-warning font-weight-bold font-size-h6 d-flex justify-content-center"
                >
                  Order In Progress
                </a>
                <div className="orders">{allCount.in_progress}</div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-primary py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Clipboard-check.svg"
                    )}
                  ></SVG>
                </span>
                <a
                  href="/history/completed-orders"
                  className="text-primary font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Accepted Orders
                </a>
                <div className="completed-orders">{allCount.accpeted_orders}</div>
              </div>
          
            
              <div
                className="col-12 col-sm-12 col-md-5  bg-light-danger py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/File-done.svg")}
                  ></SVG>
                </span>
                <a
                  href="/history/order-approved"
                  className="text-danger font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Approved Orders
                </a>
                <div className="approved-orders">
                  {allCount.approved_orders}
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-warning py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Deleted-file.svg"
                    )}
                  ></SVG>
                </span>
                <a
                  href="/history/order-rejected"
                  className="text-warning font-weight-bold font-size-h6 d-flex justify-content-center"
                >
                  Rejected Order
                </a>
                <div className="orders">{allCount.rejected_orders}</div>
              </div>
            
           
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-primary py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                  ></SVG>
                </span>
                <a
                  href="/history/order-completed"
                  className="text-primary font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Delivered Orders
                </a>
                <div className="completed-orders">
                  {allCount.delivered_orders}
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-danger py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                  ></SVG>
                </span>
                <a
                  href="/history/order-notassigned"
                  className="text-danger font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Not Assigned Orders
                </a>
                <div className="completed-orders text-danger">
                  {allCount.not_assigned}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card-spacer ">
          <div className="container-fluid">
            <div className="row m-0 justify-content-around">
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-warning py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                  ></SVG>
                </span>

                <a
                  href="/history/allorders"
                  className="text-warning font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  All Orders
                </a>
                <div className="approved-orders text-warning">
                  {allCount.all_orders}
                  {/* {orderinprogress} */}
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-primary py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Add-user.svg"
                    )}
                  ></SVG>
                </span>
                <a
                  href="/history/order-progess"
                  className="text-primary font-weight-bold font-size-h6 d-flex justify-content-center"
                >
                  Orders in progress
                </a>
                <div className="completed-orders">{allCount.in_progress}</div>
              </div>
            
            
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-danger py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  ></SVG>
                </span>
                <a
                  href="/history/order-approved"
                  className="text-danger font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Approved Orders
                </a>
                <div className="approved-orders">
                  {allCount.approved_orders}
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-success py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Urgent-mail.svg"
                    )}
                  ></SVG>
                </span>
                <a
                  href="/history/order-completed"
                  className="text-success font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Accepted Order
                </a>
                <div className="order-detail">{allCount.accepted_orders}</div>
              </div>
            
            
              <div
                className="col-12 col-sm-12 col-md-5 bg-light-primary py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  ></SVG>
                </span>
                <a
                  href="/history/order-rejected"
                  className="text-primary font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Rejected Orders
                </a>
                <div className="approved-orders text-primary">
                  {allCount.rejected_orders}
                </div>
              </div>

              <div
                className="col-12 col-sm-12 col-md-5 bg-light-warning py-8 rounded-xl mb-7"
                style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
              >
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  ></SVG>
                </span>
                <a
                  href="/history/order-notassigned"
                  className="text-warning font-weight-bold font-size-h6 mt-2 d-flex justify-content-center"
                >
                  Not Assigned Orders
                </a>
                <div className="approved-orders text-warning">
                  {allCount.not_assigned}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return "$" + val + " thousands";
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3,
    },
  };
  return options;
}
