import React, {useEffect, useState} from "react";
// import objectPath from "object-path";
// import {useHtmlClassService} from "../../layout";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import Axios from "axios";
import { baseUrl } from "../../../services/config";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";


export function WeeklyIncome() {
    // const uiService = useHtmlClassService();
    // const layoutProps = useMemo(() => {
    //     return {
    //         demo: objectPath.get(
    //             uiService.config,
    //             "demo"
    //         )};
    // }, [uiService]);
    let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }

    const authtoken = localStorage.getItem("userToken");
    const [payment,setPayment]=useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        paymentAll()
    },[])

    const paymentAll = () => {
        const data = {
            all_records: 1,
        };
        setLoading(true);
        if(user.role == "Admin"){
          Axios.post(`${baseUrl}/payment_history`,data, {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
            .then((res) => {
              console.log(res.data.payload);
              setPayment(res.data.payload);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
        else{
            Axios.post(`${baseUrl}/payment_history_client`, {
                headers: {
                  Authorization: `Bearer ${authtoken}`,
                },
              })
                .then((res) => {
                  console.log(res.data.payload);
                  setPayment(res.data.payload);
                  setLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
        }
       
        
      };
    return (
        <div className={"card card-custom card-stretch gutter-b"}>
        {/* begin::Body */}
        <div className="card-body py-0">
            <div className="font-size-h3 mt-5">
                Total Payment {payment.total_charges}
            </div>
              <div className="table-responsive">
            <table
              className="table table-vertical-center mt-5"
              id="kt_advance_table_widget_1"
            >
              <thead className="thead-dark ">
                <tr className="text-left">
                  <th>Voucher ID</th>
                  <th>Code No</th>
                  <th>Charges</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70vh",
                    width: "70vw",
                    position: "absolute",
                  }}
                >
                  <Spinner animation="border" variant="dark" />
                </div>
              ) : (
                
                <>
                 
                  { payment?.paymentVouchers ? payment?.paymentVouchers?.data.map((item, index) => (
                    <tr key={index}>
                        <td>{item.voucher_id}</td>
                        <td>{item.order.code_no}</td>
                        <td>{item.charges}</td>
                        <td>{new Date(item.created_at).toLocaleString().slice(0,9)}</td>
                        <td>
                        {/* <div className="row"> */}
                        <Link
                          to={`/order/show/${item.order_id}`}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Visible.svg"
                              )}
                            ></SVG>
                          </span>
                        </Link>
                        {/* </div> */}
                        </td>
                     </tr>
                  )) : <tr>
                  <td colSpan={5}>

                  <h1 className="font-weight-boldest">No payment found</h1>
                  </td>
                </tr>}
                 </>
              )}
              </tbody>
                  </table>
                  </div>
                  
        </div>
        </div>
    );
}