import React, { useMemo } from "react";
import objectPath from "object-path";
import { Paper } from "@material-ui/core";
import "react-chat-widget/lib/styles.css";
import { Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHtmlClassService } from "../../layout";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import Spinner from "react-bootstrap/Spinner";
import { set } from "lodash";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import Axios from "axios";

export function History() {
  const uiService = useHtmlClassService();
  let {id}=useParams();

    let user;
    if (localStorage.getItem("userDetails")) {
      user = JSON.parse(localStorage.getItem("userDetails"));
    }


  
  const layoutProps = useMemo(() => {
    return {
      demo: objectPath.get(uiService.config, "demo"),
    };
  }, [uiService]);

  const authtoken = localStorage.getItem("userToken");
  const [showOrder, setShowOrder] = useState();
  const [showOrderList, setShowOrderList] = useState();
  const [searchText,setSearchText]=useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(id==="allorders")
    {

      OrderData();
    }
    else if (id==="order-progess")
    {
      InProgressOrders()
    }
    else if (id==="order-approved")
    {
      ApprovedOrders()
    }
    else if (id==="order-completed")
    {
      DeliverOrders()
    }
    else if (id==="order-rejected")
    {
      RejectedOrders()
    }
    else if(id==="completed-orders"){
      AdminCompletedOrders()
    }
    else if(id==="order-notassigned"){
      NotAssignedOrders()
    }
    
  }, []);

  const OrderData = () => {
    setLoading(true);
    fetch(`${baseUrl}/customer_all_orders`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("history Data", data.payload);
        setShowOrder(data.payload.data);
        setShowOrderList(data.payload.data);
        setLoading(false);
        // toast('Order placed Successfully');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };


  const InProgressOrders = () => {
    // const data = {
    //   // user_id: user.id,
    //   order_status_id: 3,
    // };

    const data =new FormData();
    data.append("order_status_id[0]",3)
    if(user.role == "Admin"){
      Axios.post(`${baseUrl}/admin/orders_for_status`,data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
    else{
      Axios.post(`${baseUrl}/customer_orders_for_status`,data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
   
     

      console.log("Working")
    
  };

  const ApprovedOrders = () => {
    // const data = {
    //   // user_id: user.id,
    //   order_status_id: 9,
    // };

    const data =new FormData();
    data.append("order_status_id[0]",9)
    if(user.role == "Admin"){
      Axios.post(`${baseUrl}/admin/orders_for_status`,data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
    else{
   
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
      }
    
  };

  const DeliverOrders = () => {
    // const data = {
    //   // user_id: user.id,
    //   order_status_id: 2,
    // };

    const data =new FormData();
    data.append("order_status_id[0]",2)
    if(user.role == "Admin"){
      Axios.post(`${baseUrl}/admin/orders_for_status`,data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
    else{
   
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
      }
    
  };

  const AdminCompletedOrders = () => {
    // const data = {
    //   user_id: user.id,
    //   order_status_id: 2,
    // };

    const data =new FormData();
    data.append("order_status_id[0]",2)
    if (user.role == "Admin") {
      Axios.post(`${baseUrl}/admin/orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    } else {
      // Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
      //   headers: {
      //     Authorization: `Bearer ${authtoken}`,
      //   },
      // })
      //   .then((res) => {
      //     console.log(res.data);
      //     setUserCompletedOrders(res.data.payload.count);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     // setLoading(false)
      //   });
    }
  };

  const RejectedOrders = () => {
    // const data = {
    //   // user_id: user.id,
    //   order_status_id: 8,
    // };
    const data =new FormData();
    data.append("order_status_id[0]",8)
    if(user.role == "Admin"){
      Axios.post(`${baseUrl}/admin/orders_for_status`,data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
    else{
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
      }
    
  };

  const NotAssignedOrders = () => {
    const data =new FormData();
    data.append("order_status_id[0]",1)
    data.append("order_status_id[0]",2)
    // const data = {
    //   // user_id: user.id,
    //   order_status_id: 1,
    // };
    if(user.role == "Admin"){
      Axios.post(`${baseUrl}/admin/orders_for_status`,data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
    }
    else{
      Axios.post(`${baseUrl}/customer_orders_for_status`, data, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          setShowOrder(res.data.payload.data);
          setShowOrderList(res.data.payload.data);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false)
        });
      }
    
  };

  const ShowOrder = (id) => {
    history.push(`/order/show/${id}`);
  };

  // const updateOrder = (id) => {
  //   console.log(id);
  //   fetch(`${baseUrl}/update-order/${id}`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       console.log(response);
  //       OrderData();
  //       // setShowOrder([...data.payload]);
  //       // toast('Order placed Successfully');
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  console.log("param====>"+id);

  function onchangeSearchHandler (text){
    // console.log(text)
    setSearchText(text);
    let tempArr = showOrder.filter(item=>{
      return text.length>0 ?item.code_no.includes(text)|| item.subject.name.includes(text) || item.type_of_work.name.includes(text) || item.type_of_paper.name.includes(text) :true;
    })
    setShowOrderList(tempArr);
  }
  return (
    <>
      <div className={"card card-custom card-stretch gutter-b"}>
        {/* begin::Body */}
        <div className="card-body py-0">
        <div className="col-md-3 float-right mt-5">
           
           <input type="text" className="form-control" name="searchText" placeholder="Search"  onChange={e=>onchangeSearchHandler(e.target.value)} />
           
           </div>
        
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-vertical-center mt-5"
              id="kt_advance_table_widget_1"
            >
              <thead className="thead-dark ">
                <tr className="text-left">
                  <th>Order ID</th>
                  <th>Subject</th>
                 
                  <th>Type of Work</th>
                  <th>Type of Paper</th>
                  <th>Sources Required</th>
                  <th>Order Status</th>
                  <th>Payment Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
                <tr>
                  <td colSpan={9}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70vh",
                    width: "70vw",
                    position: "absolute",
                  }}
                >
                  <Spinner animation="border" variant="dark" />
                </div>
                </td>
                </tr>
              ) : (
                
               <>
                 
                  {showOrderList?.map((order, index) => (
                    <tr key={index}>
                      <td >
                      <Link
                          to={user.role == "Client"? `/ViewOrder/${order.id}` : `/order/show/${order.id}`}
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {order.code_no}
                          </Link>
                       
                      </td>
                      <td className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                      <Link
                          to={user.role == "Client"? `/ViewOrder/${order.id}` : `/order/show/${order.id}`}
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {order.subject?.name}
                        </Link>
                      </td>
                   
                      <td className="text-dark-75 text-hover-primary mb-1 font-size-lg">
                      <Link
                          to={user.role == "Client"? `/ViewOrder/${order.id}` : `/order/show/${order.id}`}
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {order.type_of_work?.name}
                      </Link>
                      </td>
                      <td className="text-dark-75 text-hover-primary mb-1 font-size-lg">
                      <Link
                          to={user.role == "Client"? `/ViewOrder/${order.id}` : `/order/show/${order.id}`}
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {order.type_of_paper?.name}
                       </Link>
                      </td>
                      <td className=" text-hover-primary mb-1 font-size-lg text-center">
                        
                          {order.sources_required}
                        
                      </td>
                      <td className={order?.order_status?.name ==="NOT ASSIGNED"?"text-warning  font-weight-bolder text-hover-primary mb-1 font-size-lg" : "text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"}>
                       
                          {order?.order_status?.name}
                          {/* Not Assigned */}
                        
                      </td>
                      <td
                      
                      >
                        {order.payment_status_id == 2 ? (
                          <>
                            <p className="text-danger font-weight-bolder  mb-1 font-size-lg">
                              Not Paid
                            </p>
                          </>
                        ) : (
                          <p className="text-success font-weight-bolder  mb-1 font-size-lg">
                            Paid
                          </p>
                        )}
                      </td>
                      <td>
                        <div className="row">
                        <Link
                          to={user.role == "Client"? `/ViewOrder/${order.id}` : `/order/show/${order.id}`}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Visible.svg"
                              )}
                            ></SVG>
                          </span>
                        </Link>

                        {order?.order_status?.id == 8 ? (
                          // <button
                          //   type="button"
                          //   className="btn btn-success ml-4"
                          //   onClick={() => {
                          //     // Chat(order.id);
                          //     history.push(`/chat/${order.id}`);
                          //   }}
                          // >
                          //   Chat
                          // </button>

                          <a
                            onClick={() => {
                              // Chat(order.id);
                              history.push(`/chat/${order.id}`);
                            }}
                            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Settings-1.svg"
                                )}
                              ></SVG>
                            </span>
                          </a>
                        ) : order?.order_status?.id == 9 ? (
                          // <button
                          //   disabled="true"
                          //   type="button"
                          //   className="btn btn-success ml-4"
                          //   onClick={() => {
                          //     // Chat(order.id);
                          //     history.push(`/chat/${order.id}`);
                          //   }}
                          // >
                          //   Chat
                          // </button>

                          <a
                            disabled="true"
                            onClick={() => {
                              // Chat(order.id);
                              history.push(`/chat/${order.id}`);
                            }}
                            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Settings-1.svg"
                                )}
                              ></SVG>
                            </span>
                          </a>
                        ) : (
                          // <button
                          //   type="button"
                          //   className="btn btn-success ml-4"
                          //   onClick={() => {
                          //     // Chat(order.id);
                          //     history.push(`/chat/${order.id}`);
                          //   }}
                          // >
                          //   Chat
                          // </button>

                          <a
                            onClick={() => {
                              // Chat(order.id);
                              history.push(`/chat/${order.id}`);
                            }}
                            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Chat1.svg"
                                )}
                              ></SVG>
                            </span>
                          </a>
                        )}
                        </div>
                      </td>
                    </tr>
                  ))}
               </>
              )}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
