/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import SVG from 'react-inlinesvg';
import Profile1 from "../../../../../images/profile1.png";
import './UserProfileDropdown.css';


export function UserProfileDropdown() {
  const dispatch = useDispatch();
  // const { user } = useSelector((state) => state.user);
  let user;
  if (localStorage.getItem('userDetails')) {
    user = JSON.parse(localStorage.getItem('userDetails'));
  }
  // console.log('header user',user.role[0])
  const history = useHistory();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light',
    };
  }, [uiService]);

  const logout = () => {
    dispatch({
      type: 'ADD_USER',
      payload: {
        isAuthenticated: false,
        user: null,
        authtoken: null,
      },
    });
    localStorage.clear();
    history.push('/auth/login');

    // history.replace('/auth/login')
  };
  return (
    <Dropdown drop='down' alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id='dropdown-toggle-user-profile'>
        {/* 
        <a
          style={{ backgroundColor: "#121291", padding: "18px" }}
          className="btn btn-icon  
      
          btn-sm mx-3"

        >
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.role[0]}
          </span>

          <span style={{ padding: "10px" }} className="svg-icon svg-icon-md logouticone_span">
            <SVG style={{ margin: "10px" }} className="logouticone"
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}
            />
          </span>
        </a> */}

<div className="symbol symbol-sm bg-light-primary mr-3 flex-shrink-0">
<img src={user.profile_image.length>34 ?user.profile_image:toAbsoluteUrl('/media/users/300_21.jpg')} alt="" />
                  {/* {
                    !user.profile_image==="http://api.wittyessay.com/storage" ? 
                    <img src={user.profile_image} alt="" />
                    :
                    <img src={toAbsoluteUrl('/media/users/300_21.jpg')} alt="" />
                   
                  } */}
                  {/* <img src={user.profile_image} alt="" /> */}
                </div>

        {/* <div className={'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 drop_down_button_div'}>
          <span className='text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3'>
            {user.first_name}
           
          </span>

         
        </div> */}
      </Dropdown.Toggle>
      <Dropdown.Menu className='p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className='d-flex align-items-center p-8 rounded-top'>
                <div className='symbol symbol-md bg-light-primary mr-3 flex-shrink-0'>
                  <img src={toAbsoluteUrl('/media/users/300_21.jpg')} alt='' />
                </div>
                <div className='text-dark m-0 flex-grow-1 mr-3 font-size-h5'>
                  {/* {user.role[0]} */}

                  {/* {user.firstname} {user.lastname} */}
                </div>
                {/* <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messages
                </span> */}
              </div>
              <div className='separator separator-solid'></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className='d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top'
              style={{
                backgroundColor: '#CC3333',
                // backgroundColor:"linear-gradient(#e66465, #9198e5)",
                // backgroundImage: `url(${toAbsoluteUrl(
                //   "/media/misc/bg-1.jpg"
                // )})`,
              }}
            >
              <div className="symbol bg-secondary-o-15 mr-3">
                {/* <span className="symbol-label bg-secondary text-danger font-weight-bold font-size-h4" 
                >
                  {user.first_name[0]}
                </span> */}
                <img src={user.profile_image.length>34 ?user.profile_image:toAbsoluteUrl('/media/users/300_21.jpg')} alt="" />
              </div>
              <div style={{ backgroundColor: '#CC3333' }} className='text-white m-0 flex-grow-1 mr-3 font-size-h5'>
                {'  '}
                {user.first_name}
                {/* {user.firs_tname} {user.last_name} */}
              </div>
              {/* <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span> */}
            </div>
          )}
        </>

        <div className='navi navi-spacer-x-0 pt-5'>
          <Link to="/profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-danger" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                  <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                </div>
              </div>
            </div>
          </Link>

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a> */}
          <div className='navi-separator mt-3'></div>
              {/* <div>
                   <div className='profile-menu'>
                      <img src={Profile1} alt="" className='profile-icon'/>
                   </div>
                   <div className='profile-menu' id="profile-">
                     <a href=""  id="profile-text">Profile</a>
                   </div>
              </div> */}
          <div className='navi-footer  px-8 py-5'>
            
            <button onClick={logout} className='btn btn-light-primary font-weight-bold button_blue'>
              Sign Out
            </button>
            {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
