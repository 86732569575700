import React from 'react';
import './admindashboard.style.css';
// import { PeopleAltOutlined, Payment, Apartment } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const AdminDashboard = () => {
  const history = useHistory();
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <div style={{ cursor: 'pointer' }} onClick={() => history.push('/clients')} className='card card-1 m-2'>
              <h4>Clients world</h4>
            </div>
          </div>
        </div>

        <div className='row'></div>
      </div>
    </div>
  );
};

export default AdminDashboard;
