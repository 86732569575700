import React  from "react";
// import objectPath from "object-path";
// import {useHtmlClassService} from "../../layout";


export function Transition() {
    // const uiService = useHtmlClassService();
    // const layoutProps = useMemo(() => {
    //     return {
    //         demo: objectPath.get(
    //             uiService.config,
    //             "demo"
    //         )};
    // }, [uiService]);
    return (
        <div>
            <h1>
                Transactions Page
            </h1>
        </div>
    );
}