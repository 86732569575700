// import React, { useState } from 'react';
// import { useFormik } from 'formik';
// import { connect } from 'react-redux';
// import * as Yup from 'yup';
// import { Link } from 'react-router-dom';
// import { FormattedMessage, injectIntl } from 'react-intl';
// import { useDispatch } from 'react-redux';
// import * as auth from '../_redux/authRedux';
// import { baseUrl } from '../../../../services/config';

// const initialValues = {
//   first_name: '',
//   last_name: '',
//   email: '',
//   password: '',
//   confirm_password: '',
// };

// function editProfile(props) {
//   const authtoken = localStorage.getItem('userToken');
//   const { intl } = props;

//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const [register, setRegister] = useState(false);
//   const RegistrationSchema = Yup.object().shape({
//     first_name: Yup.string()
//       .min(3, 'Minimum 3 symbols')
//       .max(50, 'Maximum 50 symbols')
//       .required(
//         intl.formatMessage({
//           id: 'AUTH.VALIDATION.REQUIRED_FIELD',
//         })
//       ),
//     email: Yup.string()
//       .email('Wrong email format')
//       .min(3, 'Minimum 3 symbols')
//       .max(50, 'Maximum 50 symbols')
//       .required(
//         intl.formatMessage({
//           id: 'AUTH.VALIDATION.REQUIRED_FIELD',
//         })
//       ),
//     last_name: Yup.string()
//       .min(3, 'Minimum 3 symbols')
//       .max(50, 'Maximum 50 symbols')
//       .required(
//         intl.formatMessage({
//           id: 'AUTH.VALIDATION.REQUIRED_FIELD',
//         })
//       ),
//     password: Yup.string()
//       .min(3, 'Minimum 3 symbols')
//       .max(50, 'Maximum 50 symbols')
//       .required(
//         intl.formatMessage({
//           id: 'AUTH.VALIDATION.REQUIRED_FIELD',
//         })
//       ),
//     confirm_password: Yup.string()
//       .required(
//         intl.formatMessage({
//           id: 'AUTH.VALIDATION.REQUIRED_FIELD',
//         })
//       )
//       .when('password', {
//         is: (val) => (val && val.length > 0 ? true : false),
//         then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
//       }),
//   });

//   const enableLoading = () => {
//     setLoading(true);
//   };

//   const disableLoading = () => {
//     setLoading(false);
//   };

//   const getInputClasses = (fieldname) => {
//     if (formik.touched[fieldname] && formik.errors[fieldname]) {
//       return 'is-invalid';
//     }

//     if (formik.touched[fieldname] && !formik.errors[fieldname]) {
//       return 'is-valid';
//     }

//     return '';
//   };

//   const formik = useFormik({
//     initialValues,
//     validationSchema: RegistrationSchema,
//     onSubmit: (values, { setStatus, setSubmitting }) => {
//       fetch(`${baseUrl}/register`, {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${authtoken}`,
//         },
//         body: JSON.stringify(values),
//       })
//         .then((response) => response.json())
//         .then((user) => {
//           console.log('Success:', values);
//           if (user.metadata.success === true) {
//             localStorage.setItem('userDetails', JSON.stringify(user.payload.user));
//             localStorage.setItem('userToken', user.payload.token);

//             disableLoading();

//             dispatch({
//               type: 'ADD_USER',
//               payload: {
//                 isAuthenticated: true,
//                 authtoken: user.payload.token,
//                 user: user.payload.user,
//               },
//             });
//             console.log(values);
//             setRegister(true);
//             disableLoading();
//           }
//         })
//         .catch(() => {
//           setStatus('You are already registered');
//         })
//         .finally(() => {
//           disableLoading();
//           setSubmitting(false);
//         });

//       console.log(values);
//       enableLoading();
//       setSubmitting(true);
//     },
//   });

//   return (
//     <div className='login-form login-signin' style={{ display: 'block' }}>
//       <div className='text-center mb-10 mb-lg-20'>
//         <h3 className='font-size-h1'>
//           <FormattedMessage id='AUTH.REGISTER.TITLE' />
//         </h3>
//         <p className='text-muted font-weight-bold'>Edit Your Profile</p>
//       </div>

//       <form
//         id='kt_login_signin_form'
//         className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
//         // onSubmit={formik.handleSubmit}
//       >
//         {formik.status && (
//           <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
//             <div className='alert-text font-weight-bold'>{formik.status}</div>
//           </div>
//         )}

//         <div className='form-group fv-plugins-icon-container'>
//           <input
//             placeholder='Full name'
//             type='text'
//             className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('first_name')}`}
//             name='first_name'
//             {...formik.getFieldProps('first_name')}
//           />
//           {formik.touched.first_name && formik.errors.first_name ? (
//             <div className='fv-plugins-message-container'>
//               <div className='fv-help-block'>{formik.errors.first_name}</div>
//             </div>
//           ) : null}
//         </div>

//         <div className='form-group fv-plugins-icon-container'>
//           <input
//             placeholder='Last name'
//             type='text'
//             className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('last_name')}`}
//             name='last_name'
//             {...formik.getFieldProps('last_name')}
//           />
//           {formik.touched.last_name && formik.errors.last_name ? (
//             <div className='fv-plugins-message-container'>
//               <div className='fv-help-block'>{formik.errors.last_name}</div>
//             </div>
//           ) : null}
//         </div>

//         <div className='form-group fv-plugins-icon-container'>
//           <input
//             placeholder='Email'
//             type='email'
//             className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
//             name='email'
//             {...formik.getFieldProps('email')}
//           />
//           {formik.touched.email && formik.errors.email ? (
//             <div className='fv-plugins-message-container'>
//               <div className='fv-help-block'>{formik.errors.email}</div>
//             </div>
//           ) : null}
//         </div>

//         <div className='form-group fv-plugins-icon-container'>
//           <input
//             placeholder='Password'
//             type='password'
//             className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
//             name='password'
//             {...formik.getFieldProps('password')}
//           />
//           {formik.touched.password && formik.errors.password ? (
//             <div className='fv-plugins-message-container'>
//               <div className='fv-help-block'>{formik.errors.password}</div>
//             </div>
//           ) : null}
//         </div>

//         <div className='form-group fv-plugins-icon-container'>
//           <input
//             placeholder='Confirm Password'
//             type='password'
//             className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('confirm_password')}`}
//             name='confirm_password'
//             {...formik.getFieldProps('confirm_password')}
//           />
//           {formik.touched.confirm_password && formik.errors.confirm_password ? (
//             <div className='fv-plugins-message-container'>
//               <div className='fv-help-block'>{formik.errors.confirm_password}</div>
//             </div>
//           ) : null}
//         </div>

//         <div className='form-group d-flex flex-wrap flex-center'>
//           <button type='submit' disabled={formik.isSubmitting || !formik.isValid} className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'>
//             <span>Submit</span>
//             {loading && <span className='ml-3 spinner spinner-white'></span>}
//           </button>

//           {/* <Link to='/auth/login'>
//             <button type='button' className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'>
//               Cancel
//             </button>
//           </Link>
//           {register ? (
//             <p className='text-success'>
//               You Are Registered Successfully{' '}
//               <Link to='/auth/login' className='font-weight-bold ml-2'>
//                 Login
//               </Link>{' '}
//             </p>
//           ) : (
//             <p className='text-danger'>You Are Not Registered</p>
//           )} */}
//         </div>
//       </form>
//     </div>
//   );
// }
// export default editProfile
// // export default injectIntl(connect(null, auth.actions)(editProfile));

import React from "react";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import { baseUrl } from "../../../services/config";
// import Axios from "axios";

const EditProfile = () => {
  // const [country, setCountry] = React.useState('');
  // const [city, setCity] = React.useState('');

  // const [editprofile, setEditProfile] = useState();
  // const [state, setstates] = useState([]);
  // const [country, setCountry] = useState([]);
  // const [selectedcountry, setselectedCountry] = useState("");
  // const [city, setCity] = useState([]);
  // const [selectedcity, setSelectedCity] = useState("");
  // const [mobile, setmobile] = useState([]);
  // const [image, setImage] = useState([]);
  // const [selectedState, setselectedState] = useState("");

  // const authtoken = localStorage.getItem("userToken");

  // useEffect(() => {
  //   // EditUserProfile();
  //   getStates();
  //   getCountries();
  // }, []);

  // let user;
  // if (localStorage.getItem("userDetails")) {
  //   user = JSON.parse(localStorage.getItem("userDetails"));
  // }

  // const getStates = () => {
  //   Axios.get(`${baseUrl}/state`, {
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((resp) => {
  //       setstates(resp.data.payload.states);
  //       console.log("statesss", resp.data.payload.states);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const getCountries = () => {
  //   Axios.get(`${baseUrl}/country`, {
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((resp) => {
  //       setCountry(resp.data.payload.countries);
  //       console.log("country", resp.data.payload.countries);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const EditUserProfile = () => {
  //   const data = {
  //     name: user.name,
  //   }
  //   Axios.post(`${baseUrl}/update-profile`,data,{
  //     headers:{
  //       'Authorization': `Bearer ${authtoken}`,
  //     }
  //   }).then(res=>{
  //     console.log(res.data)
  //     // setAllOrders(res.data.payload.count);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     // setLoading(false)
  //   });
  // };

  // const initialValues = {
  //   name: "",
  //   mobile: "",
  //   state: "",
  //   country: "",
  //   city: "",
  //   country_id: "",
  //   // state_id: "",
  //   // city_id: "",
  // };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }

  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }

  //   return "";
  // };

  // const EditProfileSchema = yup.object({
  //   name: yup
  //     .string("Enter your First Name")
  //     .min(3, "Minimum 3 symbols")
  //     .max(50, "Maximum 50 symbols")
  //     .required("First Name is required"),
  //   // lastname: yup.string()
  //   // .min(3, 'Minimum 3 symbols')
  //   // .max(50, 'Maximum 50 symbols')
  //   // .required('Last Name is Required'),
  //   mobile: yup
  //     .string()
  //     .min(11, "Phone Number must be in 11 digits")
  //     .required("Phone Number is Required"),
  //   country: yup.string().required("Country name is Required"),
  //   city: yup.string().required("City name is Required"),
  // });
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: EditProfileSchema,
  //   onSubmit: (values) => {
  //     console.log("form dataaaa values", values);
  //     // setloading(true)
  //     const fdata = new FormData();

  //     fdata.append("name", values.name);
  //     fdata.append("email", values.email);
  //     fdata.append("mobile", values.mobile);
  //     fdata.append("state_id", selectedState);
  //     fdata.append("country_id", selectedcountry);
  //     fdata.append("city_id", selectedcity);

  //     for (var values of fdata.values()) {
  //       console.log(values);
  //     }

  //     // const data = {
  //     //   name: user.name,
  //     //   mobile: user.mobile,
  //     //   state_id: selectedState,
  //     //   country_id: selectedcountry,
  //     //   city_id: selectedcity
  //     // }

  //     Axios.post(`${baseUrl}/update-profile`, fdata, {
  //       headers: {
  //         Authorization: `Bearer ${authtoken}`,
  //       },
  //     })
  //       .then((res) => {
  //         console.log(res.data);
  //         // setAllOrders(res.data.payload.count);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         // setLoading(false)
  //       });
  //   },
  // });
  
  // const onchangeState = (id) => {
  //   setselectedState(id);
  //   console.log("stateIDdd", id);
  // };
  // const onchangeCountry = (id) => {
  //   setselectedCountry(id);
  //   console.log("CountryIDdd", id);

  //   Axios.get(`${baseUrl}/cities_of_country/${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((resp) => {
  //       setCity(resp.data.payload.cities);
  //       console.log("citiesss", resp.data.payload.cities);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const onchangeCity = (id) => {
  //   setSelectedCity(id);
  //   console.log("city Idddd", id);
  // };

  // const onSubmit = () => {

  // }
  return (
    <div style={{ lineHeight: "5.5rem" }}>
      {/* <form onSubmit={formik.handleSubmit}> */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Mobile"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "mobile"
            )}`}
            name="mobile"
            {...formik.getFieldProps("mobile")}
          />
          {formik.touched.mobile && formik.errors.mobile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.mobile}</div>
            </div>
          ) : null}
        </div> */}
        {/* <TextField
          fullWidth
          variant="outlined"
          id="name"
          name="name"
          label="Full Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          {...formik.getFieldProps("name")}
        />
        <TextField
          fullWidth
          variant="outlined"
          id="mobile"
          name="mobile"
          label="Phone Number"
          value={formik.values.mobile}
          onChange={formik.handleChange}
          error={formik.touched.mobile && Boolean(formik.errors.mobile)}
          helperText={formik.touched.mobile && formik.errors.mobile}
          {...formik.getFieldProps("mobile")}
        />
        <select
          value={selectedState}
          label="Select State"
          onChange={(e) => onchangeState(e.target.value)}
          name="state_id"
          className={`form-control h-auto mb-3 py-3 px-3 ${getInputClasses(
            "state_id"
          )}`}
          // {...formik.getFieldProps("state_id")}
        >
          <option value="">Select State</option>
          {state &&
            state.map((states) => (
              <option key={states.id} value={states.id}>
                {states.name}
              </option>
            ))}
        </select>
        <select
          value={selectedcountry}
          label="Select Country"
          onChange={(e) => onchangeCountry(e.target.value)}
          name="country_id"
          className={`form-control mt-3 mb-3 h-auto py-3 px-3 ${getInputClasses(
            "country_id"
          )}`}
          // {...formik.getFieldProps("country_id")}
        >
          <option value="">Select Country</option>
          {country &&
            country.map((countries) => (
              <option key={countries.id} value={countries.id}>
                {countries.name}
              </option>
            ))}
        </select>
        <select
          value={selectedcity}
          label="Select City"
          onChange={(e) => onchangeCity(e.target.value)}
          name="city_id"
          className={`form-control mt-3 mb-3 h-auto py-3 px-3 ${getInputClasses(
            "city_id"
          )}`}
          // {...formik.getFieldProps("city_id")}
        >
          <option value="">Select City</option>
          {city &&
            city.map((cities) => (
              <option key={cities.id} value={cities.id}>
                {cities.name}
              </option>
            ))}
        </select>
        <Button
          onClick={formik.handleSubmit}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
        >
          Submit
        </Button>
      </form> */}
    </div>
  );
};

export default EditProfile;
