import React, { useMemo, useState } from "react";
import { ErrorPage6 } from "../../modules/ErrorsExamples/ErrorPage6";
import "../../../_metronic/_assets/sass/pages/error/error-6.scss";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import Axios from "axios";
const Verification = () => {
  const [loader, setLoader] = useState(false);
  let { id } = useParams();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const authtoken = localStorage.getItem("userToken");

  function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  function verify() {
    console.log(query.get("signature"));
    setLoader(true);
    console.log("Clicked", id);
    Axios.get(`${baseUrl}/verify-email/${user.id}/${id}?expires=${query.get("expires")}&signature=${query.get("signature")}`,{
      headers: {
        Authorization: `Bearer ${authtoken}`,
      }
    })
      .then((response) => {
        console.log(response);
        localStorage.setItem('userDetails', JSON.stringify(response.data.payload.user));
        setLoader(false);
        history.push("/");
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
      });
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center h-100"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/error/bg6.jpg")})`,
          }}
        >
          <div className="d-flex flex-column flex-row-fluid text-center mt-20">
            <div className="d-flex flex-column flex-row-fluid text-center">
              <h1 className="error-title font-weight-boldest text-white mb-12">
                Verify Please...
              </h1>

             
            </div>
            <button
               disabled={loader}
                className="display-4 font-weight-bold text-white btn-dark"
                onClick={(e) => verify()}
              >
                Click here for verify.
              </button>
              {loader && (
                <span className="ml-3 spinner spinner-white py-4"></span>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
