// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

// import "./common.css";
import { baseUrl } from "../../services/config";
import Axios from "axios";

const CheckoutForm = ({closethis,voucherid}) => {
  const [status, setStatus] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const authtoken = localStorage.getItem("userToken");
  console.log(voucherid)
  const submitPaid =async()=>{
    console.log("Voucher: "+voucherid)
    const data={
      payment_voucher_id:voucherid
    }

    await Axios.post(`${baseUrl}/make_voucher_paid`,data, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        console.log(res);
         window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
        
      });
    
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    stripe
      .confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: { return_url: window.location.href },
      })
      .then((res) => {
        setLoading(false);
        if (res.error) {
          console.error(res.error);
          setStatus(res.error.message);
        } else {
          submitPaid()
          console.log("payment done:", res.paymentIntent.status);
          closethis()
          setStatus(res.paymentIntent.status);
          // window.location.reload(true);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="btn btn-dark mt-3" type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Pay"}
      </button>
      {status && <p>{status}</p>}
    </form>
  );
};

const App = ({ clientSecret,closeModal,voucherid }) => {


  console.log("this is ",clientSecret)


  const stripePromise = loadStripe(
    "pk_test_51LFFGOERxhE3mQ8OOHEDE1LaJNuP0yaJhKNJYOlgNHYKAQCl78BXjzgsU9x2jKoadg9MGep5hRteLh2IKJtekP83005I1CCkG3"
  );

  // const [theme, setTheme] = React.useState("stripe");

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm closethis={closeModal} voucherid={voucherid} />
      </Elements>
    </>
  );
};

export default App;
