import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import Axios from "axios";
import { baseUrl } from "../../../services/config";
import * as yup from "yup";
import {toast} from "react-toastify";
import { updateUser } from "../../../redux/actions/action";

 function  PersonaInformation(props) {
  const authtoken = localStorage.getItem("userToken");

  const [pic, setPic] = useState();
  const dispatch = useDispatch();
  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
    // console.log(user)
  }
  const ref = useRef();
  // Fields
  const [loading, setloading] = useState(false);
  const [state, setstates] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectedcountry, setselectedCountry] = useState("");
  const [city, setCity] = useState([]);
  const [selectedcity, setSelectedCity] = useState("");
  const [selectedState, setselectedState] = useState("");
  const updateUser = useDispatch();
 
  useEffect(() => {
    // if (user.profile_image) {
    //   setPic(user.profile_image);
    // }
    getStates();
    getCountries();
    console.log(user)
  }, []);

  const getStates = () => {
    Axios.get(`${baseUrl}/state`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((resp) => {
        setstates(resp.data.payload.states);
        console.log("statesss", resp.data.payload.states);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountries = () => {
    Axios.get(`${baseUrl}/country`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((resp) => {
        setCountry(resp.data.payload.countries);
        console.log("country", resp.data.payload.countries);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeState = (id) => {
    setselectedState(id);
    console.log("stateIDdd", id);
  };

  const onchangeCountry = (id) => {
    setselectedCountry(id);
    console.log("CountryIDdd", id);

    Axios.get(`${baseUrl}/cities_of_country/${id}`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((resp) => {
        setCity(resp.data.payload.cities);
        console.log("citiesss", resp.data.payload.cities);
      })
      .catch((err) => console.log(err));
  };


  // const updateUser = useCallback(
  //   (user) => dispatch({ type: 'UPDATE_USER',payload:user }),
  //   [dispatch]
  // )

  // Methods
  const saveUser = (values) => {
    setloading(true);
    console.log(values)

    // const data = {
    //     first_name: values.first_name,
    //     last_name: values.last_name,
    //     mobile: values.mobile,
    //     dob: user.dob,
    //     address: values.address,
    //     email: user.email,
    //     avatar:pic,
    //     state: selectedState,
    //     country: selectedcountry,
    //     city: selectedcity,
    // }

    const fdata = new FormData();

    fdata.append("first_name", values.first_name)
    fdata.append("last_name", values.last_name)
    fdata.append("mobile", values.mobile)
    fdata.append("dob", values.dob)
    fdata.append("address", values.address)
    fdata.append("avatar", pic)
    fdata.append("state", selectedState)
    fdata.append("country", selectedcountry)
    fdata.append("city", selectedcity)
    


   


Axios.post(`${baseUrl}/update-profile`, fdata, {
  headers: {
    Authorization: `Bearer ${authtoken}`,
  },
})
  .then((res) => {
    if (res.data.metadata.success) {
      toast.success(res.data.metadata.message)
      console.log(res.data)
      localStorage.setItem('userDetails', JSON.stringify(res.data.payload.user));
      window.location.reload(true)
      setloading(false)
    
  } else {
      toast.error(res.data.metadata.message)
      setloading(false);
  }
  })
  .catch((err) => {
    console.log(err.response)
    setloading(false);
  });

    // setTimeout(() => {
    //     setloading(false)

    // }, 3000);

};
  // UI Helpers
  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    mobile: user.mobile,
    dob: user.dob,
    address: user.address,
    email: user.email,
    avatar:user.profile_image,
    state: "",
    country: "",
    city: "",
    
  };



  const EditProfileSchema = yup.object({
    first_name: yup
      .string("Enter your First Name")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("First Name is required"),
    last_name: yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last Name is Required'),
    mobile: yup
      .string()
      .min(11, "Phone Number must be in 11 digits")
      .required("Phone Number is Required"),
    // country: yup.string().required("Country name is Required"),
    // city: yup.string().required("City name is Required"),
  });


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const formik = useFormik({
    initialValues,
    validationSchema: EditProfileSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
        saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
        resetForm();
    },
});


  const onchangeCity = (id) => {
    setSelectedCity(id);
    console.log("city Idddd", id);
  };
  const getUserPic = () => {
    if (!pic) {
      return "none";
    }

    return `url(${pic})`;
  };
  const removePic = () => {
    setPic("");
  };

  

  return (

    <div className="card card-custom card-stretch">
 <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
        {/* {formik.status && (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
      {loading && <ModalProgressBar />} */}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Personal Information
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal informaiton
          </span>
        </div>
        <div className="card-toolbar">
          <button
            className="btn btn-success mr-2"
            type="submit"
          >
            {/* {formik.isSubmitting} */}
            Save Changes
          </button>
        
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Customer Info</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
            <div className="col-lg-9 col-xl-6">
              <div
                className="image-input image-input-outline"
                id="kt_profile_avatar"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/blank.png"
                  )}`,
                }}
              >
                <div
                  className="image-input-wrapper"
                  style={{ backgroundImage: `${getUserPic()}` }}
                />
                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i className="fa fa-pen icon-sm text-muted ml-8"></i>
                  <input
                    type="file"
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e)=>setPic(e.target.files[0])}
                    // {...formik.getFieldProps("avatar")}
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>
                <span
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Cancel avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
                <span
                  onClick={removePic}
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Remove avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>
              <span className="form-text text-muted">
                Allowed file types: png, jpg, jpeg.
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              First Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="First name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "first_name"
                )}`}
                name="first_name"
                {...formik.getFieldProps("first_name")}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="invalid-feedback">{formik.errors.first_name}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Last Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Last name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "last_name"
                )}`}
                name="last_name"
                {...formik.getFieldProps("last_name")}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="invalid-feedback">{formik.errors.last_name}</div>
              ) : null}
            </div>
          </div>
          {/* <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Company Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Company name"
                className={`form-control form-control-lg form-control-solid`}
                name="companyName"
                {...formik.getFieldProps("companyName")}
              />
              <span className="form-text text-muted">
                If you want your invoices addressed to a company. Leave blank to
                use your full name.
              </span>
            </div>
          </div> */}

          {user.role === "Client" && (
            <>
              <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-6">
                  <h5 className="font-weight-bold mt-10 mb-6">Contact Info</h5>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Contact Phone
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-phone"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="+1(123)112-11-11"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "mobile"
                      )}`}
                      name="mobile"
                      {...formik.getFieldProps("mobile")}
                    />
                  </div>
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div className="invalid-feedback display-block">
                      {formik.errors.mobile}
                    </div>
                  ) : null}
                  <span className="form-text text-muted">
                    We'll never share your phone with anyone else.
                  </span>
                </div>
              </div>
            
       


          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Address"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "address"
                  )}`}
                  name="address"
                  {...formik.getFieldProps("address")}
                />
              </div>
              {formik.touched.address && formik.errors.address ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.address}
                </div>
              ) : null}
            </div>
          </div>


          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
            placeholder="Date of birth"
            type="text"
            ref={ref}
            // onChange={(e) => console.log(e.target.value)}
            onFocus={() => (ref.current.type = "date")}
            onBlur={() => (ref.current.type = "text")}
            className={`form-control form-control-solid h-auto py-5 px-6 mr-1 ${getInputClasses(
              "dob"
            )}`}
            name="dob"
            {...formik.getFieldProps("dob")}
          />
              </div>
              {formik.touched.dob && formik.errors.dob ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.dob}
                </div>
              ) : null}
            </div>
          </div>
         
            
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  State
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    value={selectedState}
                    onChange={(e) => onchangeState(e.target.value)}
                    className="form-control form-control-lg form-control-solid"
                    name="state_id"
                    //  {...formik.getFieldProps("country")}
                  >
                    <option value="">Select State</option>
                    {state &&
                      state.map((states) => (
                        <option key={states.id} value={states.id}>
                          {states.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Country
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    value={selectedcountry}
                    onChange={(e) => onchangeCountry(e.target.value)}
                    className="form-control form-control-lg form-control-solid"
                    name="country_id"
                    // {...formik.getFieldProps("country")}
                  >
                    <option value="">Select Country</option>
                    {country &&
                      country.map((countries) => (
                        <option key={countries.id} value={countries.id}>
                          {countries.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">City</label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    value={selectedcity}
                    onChange={(e) => onchangeCity(e.target.value)}
                    className="form-control form-control-lg form-control-solid"
                    name="city_id"
                    //  {...formik.getFieldProps("country")}
                  >
                    <option value="">Select City</option>
                    {city &&
                      city.map((cities) => (
                        <option key={cities.id} value={cities.id}>
                          {cities.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>

      </div>



   
  );
}

export default connect(null, auth.actions)(PersonaInformation) ;
