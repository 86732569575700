import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { BuilderPage } from './pages/Builder/BuilderPage';
import { PolicyPage } from './pages/Policy/PolicyPage';
import { AboutUsPage } from './pages/AboutUs/AboutUsPage';
import { MyPage } from './pages/MyPage';
import { DashboardPage } from './pages/DashBoard/DashboardPage';
import AdminDashBoard from '../Components/AdminPanel/AdminDashBoard';
import ClientPage from './pages/ClientPage/ClientPage';
import OrdersPage from './pages/Order/OrdersPage';
import HireExpert from './pages/expert/HireExpert';
import ShowOrder from './pages/Order/ShowOrder/ShowOrder';
import ChatPanel from '../Components/ChatPanel/ChatPanel';
import { TransitionPage } from './pages/Transition/TransitionPage';
import { HistoryPage } from './pages/History/HistoryPage';
import { WeeklyIncome } from '../_metronic/_partials';
import EditProfile from '../_metronic/_partials/profile/EditProfile';
import ViewOrder from './pages/History/ViewOrder';
import UserProfilePage  from './modules/UserProfile/UserProfilePage';
import PersonaInformation from './modules/UserProfile/PersonaInformation';
import Verification from './pages/Verification/Verification';

const GoogleMaterialPage = lazy(() => import('./modules/GoogleMaterialExamples/GoogleMaterialPage'));
const ReactBootstrapPage = lazy(() => import('./modules/ReactBootstrapExamples/ReactBootstrapPage'));
const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'));
// const UserProfilepage = lazy(() => import('./modules/UserProfile/UserProfilePage'));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from='/' to='/dashboard' />
        }
        <ContentRoute role='User' path='/dashboard' component={DashboardPage}  />
        {/* <ContentRoute role='User' path='/profile' component={ProfilePage} /> */}
        <ContentRoute role='User' path='/transition' component={TransitionPage} />
        <ContentRoute role='User' path='/history/:id' component={HistoryPage} />
        <ContentRoute role='User' path='/EditProfile' component={EditProfile} />
        <ContentRoute role='User' path='/ViewOrder/:id' component={ViewOrder} />
        <ContentRoute  path='/profile' component={PersonaInformation} />

        {/* User Roles */}

        <ContentRoute path='/Expert' component={HireExpert} />
        {/* <ContentRoute path='/user' component={UserDashBoard} /> */}
        <ContentRoute role='User' path='/builder' component={BuilderPage} />
        <ContentRoute role='User' path='/my-page' component={MyPage} />
        <ContentRoute role='User' path='/policy-page' component={PolicyPage} />
        <ContentRoute role='User' path='/about-page' component={AboutUsPage} />

        {/* Admin Roles */}
        <ContentRoute role='Admin' path='/admin' component={AdminDashBoard} />
        <ContentRoute role='Admin' path='/orders' component={OrdersPage} />
        <ContentRoute role='Admin' path='/order/show/:id' component={ShowOrder} />
        {/* <ContentRoute role='Admin' path='/chat' component={Chat} /> */}
        <ContentRoute role='Admin' path='/clients' component={ClientPage} />
        {/* <ContentRoute role='Admin' path='/newusers' component={NewUsers} /> */}
        <ContentRoute  path='/weeklyincome' component={WeeklyIncome} />


        <ContentRoute  path='/verification/:id' component={Verification} />

        {/* Chat Routes */}
        <ContentRoute path='/chat/:id' component={ChatPanel} />

        <Route path='/google-material' component={GoogleMaterialPage} />
        <Route path='/react-bootstrap' component={ReactBootstrapPage} />
        <Route path='/e-commerce' component={ECommercePage} />
        {/* <Route path='/user-profile' component={UserProfilePage} /> */}
        <Redirect to='error/error-v1' />
      </Switch>
    </Suspense>
  );
}
